<template>
  <div id="appWrapper">
    <div
      v-if="isClosed == 'False'"
      id="app"
      :class="
        'container-fluid mx-0 px-0 mx-lg-auto' + (locale === 'tn' ? ' px-lg-3' : '') + (loading ? ' disabled-area' : '')
      "
    >
      <modal v-show="isIE" @close="closeBrowserModal" :isModalVisible="isIE" class="relative-650-modal">
        <h2 slot="header">Browser Warning</h2>
        <div slot="body" class="row text-center">
          <div class="col-12">
            Your browser is not supported by {{ $t('message.textID') }} and many features will not work.
          </div>
          <div class="col-12">
            <strong>Please use one of the following browsers:</strong>
          </div>
          <div class="col-12 pt-3 text-center">
            <span class="text-center d-inline-block">
              <a href="https://getfirefox.com/" target="_blank">
                <img src="./images/logo/firefox-250.png" alt="Firefox" style="width: 150px" />
                <br />
                Firefox
              </a>
            </span>
            <span class="text-center d-inline-block ml-3">
              <a href="https://www.google.com/chrome/" target="_blank">
                <img src="./images/logo/chrome-250.png" alt="Google Chrome" style="width: 150px" />
                <br />
                Chrome
              </a>
            </span>
            <span class="text-center d-inline-block ml-3">
              <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">
                <img src="./images/logo/edge-250.png" alt="Microsoft Edge" style="height: 150px" />
                <br />
                Edge
              </a>
            </span>
            <span class="text-center d-inline-block ml-3">
              <a href="https://www.apple.com/safari/" target="_blank">
                <img src="./images/logo/safari-250.png" alt="Safari" style="height: 150px" />
                <br />
                Safari
              </a>
            </span>
            <div class="col"></div>
          </div>
        </div>
      </modal>
      <a href="#content" class="sr-only sr-only-focusable">Skip to main content</a>
      <div class="row mx-0 px-0">
        <div id="header" class="col-12 mx-0 px-0 pb-0">
          <nav-menu params="route: route"></nav-menu>
          <wfrheader></wfrheader>
        </div>
        <div id="content" class="col-12 mx-0 px-0 pt-lg-0">
          <div :class="imageClass">
            <!--Image of man typing on top page-->
          </div>
          <div>
            <router-view></router-view>
          </div>
        </div>
      </div>
      <!--Curvature-->
      <div id="curvature_holder" v-if="locale !== 'tn'">
        <div id="curvature"></div>
      </div>
      <!--END curvature-->
      <wfrfooter @busy="pageBusy($event)"></wfrfooter>
      <div v-if="loading" class="loader">
        <icon icon="spinner" pulse />
        <p><em>Loading...</em></p>
      </div>
    </div>
    <div v-if="isClosed == 'True'">
      <uatclosed v-if="isUat"></uatclosed>
      <maintenance v-else></maintenance>
    </div>
  </div>
</template>

<script>
import Header from '@static/header';
import Footer from '@static/footer';
import NavMenu from '@components/nav-menu';
import UATClosed from '@static/uatclosed';
import Maintenance from '@static/maintenance';
import { mapGetters, mapActions } from 'vuex';
import { locale } from '@scripts/localized';

export default {
  components: {
    'nav-menu': NavMenu,
    wfrheader: Header,
    wfrfooter: Footer,
    uatclosed: UATClosed,
    maintenance: Maintenance
  },

  data() {
    return {
      isBusy: false,
      isIE: false,
      isClosed: false,
      isDev: false,
      isUat: false,
      isProd: false,
      envIndicatorText: '',
      locale: locale,
      showLocalEnvIndicator: false
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'claims', 'profile', 'rolesSuccess']),
    imageClass: function () {
      if (this.$route && (this.$route.name == 'home' || this.$route.name == 'dashboard-quicksight')) {
        return 'display-none';
      }
      return this.isAuthenticated ? 'top-bg-image-nav' : 'top-bg-image';
    },
    loading: function () {
      if (this.isBusy) return true;
      if (this.isAuthenticated) {
        if (this.claims.length == 0 || !this.profile || !this.profile.id || !this.rolesSuccess) return true;
      }
      return false;
    }
  },
  created() {
    this.$messageHub.$on('notification-received', this.notificationToast);
    let key = 'SplashScreen';
    this.isBusy = true;
    this.$store
      .dispatch('configRequest', key)
      .then((result) => {
        this.isClosed = result;
        if (this.isClosed) {
          key = 'BaseUrl';
          this.$store
            .dispatch('configRequest', key)
            .then((result) => {
              this.isBusy = false;
              this.setEnvIndicatorText();
              window.addEventListener('resize', this.setEnvIndicatorText);
              if (result.indexOf('uat.') >= 0) {
                this.isUat = true;
              } else if (result.indexOf('dev.') >= 0) {
                this.isDev = true;
              } else if (result.indexOf('alabamapathways.') >= 0) {
                this.isProd = true;
              } else if (this.showLocalEnvIndicator && result.indexOf('localhost') >= 0) {
                this.isProd = true;
              }
            })
            .catch((errors) => {
              this.isBusy = false;
              this.errors = errors.response.data;
            });
        } else {
          this.isBusy = false;
        }
      })
      .catch((errors) => {
        this.isBusy = false;
        this.errors = errors.response.data;
      });
    let edgever;
    const ua = navigator.userAgent;
    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      edgever = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    this.isIE = /*@cc_on!@*/ false || !!document.documentMode || edgever <= 18;
  },
  beforeDestroy() {
    // Make sure to cleanup SignalR event handlers when removing the component
    this.$messageHub.$off('notification-received', this.notificationToast);
  },
  mounted() {
    if (this.isAuthenticated) {
      this.isBusy = true;
      this.$store
        .dispatch('restoreContext')
        .then((result) => {
          this.isBusy = false;
        })
        .catch(() => {
          this.isBusy = false;
          this.$router.push('/');
        });
    }
  },
  methods: {
    checkEnvIndicatorVisible() {
      const showEnvIndicator = false;
      return showEnvIndicator;
    },
    setEnvIndicatorText() {
      if (window.innerWidth > 991) {
        this.envIndicatorText =
          '';
      } else {
        this.envIndicatorText =
          '';
      } 
    },
    closeBrowserModal() {
      this.isIE = false;
    },
    notificationToast(hubResponse) {
      if (hubResponse && hubResponse.notification && hubResponse.notification.subject) {
        // Use a shorter name for this.$createElement
        const h = this.$createElement;
        // Create the message
        const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, [hubResponse.notification.subject]);
        // Create the title
        const vNodesTitle = h('div', { class: ['d-flex', 'flex-grow-1', 'align-items-center', 'mr-2'] }, [
          h('icon', { props: { icon: 'bell', size: 'lg' } }),
          h('strong', { class: 'mx-2' }, 'New Notification')
        ]);
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          variant: 'info',
          solid: true,
          isStatus: true,
          toaster: 'b-toaster-bottom-right',
          to: '/dashboard/notifications'
        });
      }
    },
    pageBusy(eventVal) {
      this.isBusy = eventVal;
    }
  }
};
</script>

<style lang="scss">
@use './css/messaging-notifications.css';
@use './css/form.css';
@use './css/modal.css';
@use './css/grid.css';
@use './css/profile.css';

@use './css/home.scss' as home;
@use './css/site.scss' as site;
@use './css/header.scss' as header;
@use './css/dashboard.scss' as dashboard;
@use './css/footer.scss' as footer;
@use './css/login-register.scss' as login;
@use './css/static.scss' as static;

@use './css/home-tn.scss' as tnHome;
@use './css/site-tn.scss' as tnSite;
@use './css/header-tn.scss' as tnHeader;
@use './css/dashboard-tn.scss' as tnDashboard;
@use './css/footer-tn.scss' as tnFooter;
@use './css/login-register-tn.scss' as tnLogin;
@use './css/static-tn.scss' as tnStatic;

@use './css/site-al.scss' as alSite;
@use './css/home-al.scss' as alHome;
@use './css/header-al.scss' as alHeader;
@use './css/dashboard-al.scss' as alDashboard;
@use './css/footer-al.scss' as alFooter;
@use './css/login-register-al.scss' as alLogin;
@use './css/static-al.scss' as alStatic;

@if $tenant == 'jh' {
  @include home.mixHome;
  @include site.mixSite;
  @include header.mixHeader;
  @include dashboard.mixDashboard;
  @include footer.mixFooter;
  @include login.mixLogin;
  @include static.mixStatic;
} @else if $tenant == 'tn' {
  @include tnHome.mixTNHome;
  @include tnSite.mixTNSite;
  @include tnHeader.mixTNHeader;
  @include tnDashboard.mixTNDashboard;
  @include tnFooter.mixTNFooter;
  @include tnLogin.mixTNLogin;
  @include tnStatic.mixTNStatic;
} @else if $tenant == 'al' {
  @include alHome.mixALHome;
  @include alSite.mixALSite;
  @include alHeader.mixALHeader;
  @include alDashboard.mixALDashboard;
  @include alFooter.mixALFooter;
  @include alLogin.mixALLogin;
  @include alStatic.mixALStatic;
}
</style>
