<template>
  <section class="container-fluid">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div :class="isBusy ? 'disabled-area has-text-centered' : 'has-text-centered'">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Reports</h1>
          <p>Select a report or search by {{ $t('message.agency').toLowerCase() }} name to download reports.</p>
        </div>
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle="'accordion_0'"
              @click="setExpanded(0)"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon
                    :icon="expanded == 0 ? 'caret-down' : 'caret-right'"
                    class="ml-2"
                    style="color: dimgray"
                  ></icon>
                </button>
                <span class="ml-4">General Reports</span>
              </span>
            </b-card-header>
            <b-collapse
              :id="'accordion_0'"
              accordion="access-accordion"
              role="tabpanel"
              :visible="true"
            >
              <b-card-body>            
                <div class="row">
                    <b-form-group>
                    <b-form-checkbox-group
                        id="cbGrpGeneralReports"
                        v-model="reportsSelected"
                        :options="options"
                        stacked
                    >
                    </b-form-checkbox-group>
                    </b-form-group>
                </div>
                <div class="row">
                    <b-form-group label="Report Format:">
                    <b-form-radio-group id="rbGrpFormat" v-model="formatSelected" stacked>
                        <b-form-radio name="rbCSV" value="csv"
                        ><icon icon="file-csv" size="lg" class="mr-1"></icon>CSV</b-form-radio
                        >
                        <b-form-radio disabled name="rbPDF" value="pdf"
                        ><icon icon="file-pdf" size="lg" class="mr-1"></icon>PDF</b-form-radio
                        >
                    </b-form-radio-group>
                    </b-form-group>
                </div>
                <div class="row align-items-center">
                    <div class="text-center">
                    <b-button
                    variant="wfr"
                    @click="downloadReports"
                    :disabled="reportsSelected.length == 0 || formatSelected.length == 0"
                    >Run Report<span v-if="reportsSelected.length > 1">s</span></b-button
                    >
                    </div>
                </div>          
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle="'accordion_1'"
              @click="setExpanded(1)"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon
                    :icon="expanded == 1 ? 'caret-down' : 'caret-right'"
                    class="ml-2"
                    style="color: dimgray"
                  ></icon>
                </button>
                <span class="ml-4">Agency Reports</span>
              </span>
            </b-card-header>
            <b-collapse
              :id="'accordion_1'"
              accordion="access-accordion"
              role="tabpanel"
              :visible="false"
            >
              <b-card-body>    
                <div>
                    <agencySearch @input="agencySelected"></agencySearch>
                </div>
                <div class="col-12 col-lg-7" v-if="filterOrganizationId">
                    <b-form-group>
                        <b-form-checkbox-group
                            id="cbGrpStaffReports"
                            v-model="agencyReportsSelected"
                            :options="agencyOptions"
                            class="ml-5"
                            stacked
                        >
                        </b-form-checkbox-group>
                    </b-form-group>
                </div>
                <div style="min-height: 200px">
                    <div v-if="filterOrganizationId" class="ml-3">
                        <b-button variant="wfr" 
                            @click="downloadAgencyReports"
                            :disabled="agencyReportsSelected.length == 0 || !formatSelected || !filterOrganizationId">
                        <icon icon="file-csv" size="lg" class="mr-2"></icon>Run Report<span v-if="agencyReportsSelected.length > 1">s</span> for
                        {{ organizationEntity.value }}
                        </b-button>
                    </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

    </div>
  </section>
</template>

<script>
import agencySearch from './agency-autocomplete.vue';
import {getText } from '@scripts/localized';

export default {
  name: 'SupervisorReports',
  components: {
    agencySearch
  },
  data() {
    return {
      filterOrganizationId: '',
      isBusy: false,
      userEntityType: 1,
      userFormId: 1,
      entityTypeName: 'User',
      reportTypeName: 'EmployeeReport',
      organizationEntity: {},
      options: [
        { text: 'DHS Staff Verification Activity Report', value: '1' },
        { text: 'All Agencies Report', value: '2' },
        { text: 'EC Professional Record Verification Report', value: '3' }
      ],
      agencyOptions: [
        { text: 'Staff Education Records', value: '1' },
        { text: 'Staff Certification/Credential Records', value: '2' },
        { text: 'Staff Training Records', value: '3' }
      ],
      reportsSelected: [],
      agencyReportsSelected: [],
      formatSelected: 'csv',
      expanded: 0,
      titleName: 'Reports'
    };
  },
  methods: {
    setExpanded(index) {
      if (this.expanded == index) this.expanded = -1;
      else this.expanded = index;
    },
    agencySelected(organization) {
      if (organization && organization.key) {
        this.organizationEntity = organization;
        this.filterOrganizationId = organization.key;
      } else {
        this.organizationEntity = {};
        this.filterOrganizationId = '';
      }
    },
    async downloadReports() {
      for (let i = 0; i < this.reportsSelected.length; i++) {
        const report = this.options.find((report) => report.value == this.reportsSelected[i]);
        if (report) {
          await this.downloadReport(report);
        }
      }
      this.reportsSelected = [];
    },
    async downloadReport(report) {
      return await new Promise((resolve, reject) => {
        const type = report.text.replace(new RegExp(' ', 'g'), '');
        const body = {
          reportType: type,
          fileType: this.formatSelected
        };
        this.isBusy = true;
        this.$store
          .dispatch('supervisorReportRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            if (response && response.report) {
              const fileName = this.$timestampedReportName(getText('textID'), report.text, this.formatSelected);
              if (this.formatSelected == 'csv') await this.saveCsv(fileName, response.report);
              if (this.formatSelected == 'pdf') await this.savePdf(fileName, response.report);
              resolve(true);
            }
            resolve(false);
          })
          .catch((errors) => {
            this.isBusy = false;
            reject(errors);
          });
      });
    },
    async downloadAgencyReports() {
      for (let i = 0; i < this.agencyReportsSelected.length; i++) {
        const report = this.agencyOptions.find((report) => report.value == this.agencyReportsSelected[i]);
        if (report) {
          await this.downloadAgencyReport(report);
        }
      }
      this.agencyReportsSelected = [];
    },
    async downloadAgencyReport(report) {
      if (!this.filterOrganizationId) {
        const options = { title: 'Error' };
        this.$dialogs.alert(`Please select an ${getText('agency').toLowerCase()} from the dropdown menu`, options);
        return;
      }
      return await new Promise((resolve, reject) => {
        const type = report.text.replace(new RegExp(' ', 'g'), '').replace('/','');
        const body = {
          reportType: type,
          organizationId: this.filterOrganizationId,
          fileType: this.formatSelected
        };
        this.isBusy = true;
        this.$store
          .dispatch('organizationEmployeesReportRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            if (response && response.report) {
              const fileName = this.$timestampedReportName(
                this.organizationEntity.value,
                report.text,
                this.formatSelected
              );
              
              if (this.formatSelected == 'csv') await this.saveCsv(fileName, response.report);
              if (this.formatSelected == 'pdf') await this.savePdf(fileName, response.report);
              resolve(true);
            }
            resolve(false);
          })
          .catch((errors) => {
            this.isBusy = false;
            reject(errors);
          });
      });
    },
    saveCsv(filename, data) {
      const blob = new Blob([data], { type: 'text/csv' });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    }
  }
};
</script>
