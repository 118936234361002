<template>
  <section class="container-fluid">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div :class="isBusy ? 'disabled-area has-text-centered' : 'has-text-centered'">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Manage Users</h1>
        </div>
        <div>
          <grid :entityTypeId="this.userEntityType"
                :formId="userFormId"
                :entityTypeName="entityTypeName"
                :additionalActions="additionalActions"
                :checkForAdditionalActions="shouldShowAdditionalActions"
                :key="gridKey"
                :ref="'userGrid'"
                :basePageSize="gridPageSize"
                :baseCurrentPage="gridCurrentPage"
                @setPageSize="setPageSize"
                @setCurrentPage="setCurrentPage">
          </grid>
        </div>
      </div>
    </div>
    <modal v-show="isChangeEmailModalVisible"
           @close="closeChangeEmailModal"
           :isModalVisible="isChangeEmailModalVisible"
           :showBottomClose="false" >
      <h2 slot="header">
        Change Email Address: <span v-if="activeUser.fullName">{{ activeUser.fullName }}</span>
      </h2>
      <div class="text-left row" slot="body">
        <div v-if="activeUser.id && isChangeEmailModalVisible" class="w-100 m-3">
          <changeEmail :key="activeId" :user="activeUser" @dirty="formDirty($event)"></changeEmail>
        </div>
      </div>
    </modal>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">
        User Access Permissions<span v-if="activeUser.fullName"> - {{ activeUser.fullName }}</span>
      </h2>
      <userRoles slot="body" :key="activeId" @dirty="formDirty($event)" :user="activeUser"> </userRoles>
    </modal>
    <modal v-show="isUserRecordModalVisible" @close="closeUserRecordModal" :isModalVisible="isUserRecordModalVisible">
      <h2 slot="header">User Records - {{ activeUser.fullName }}</h2>
      <div class="text-left row" slot="body">
        <div v-if="activeUser.id && isUserRecordModalVisible" class="w-100 m-3">
          <userRecordsView :key="activeItemId" :user="activeUser" @dirty="formDirty($event)"></userRecordsView>
        </div>
      </div>
    </modal>
    <modal v-show="isCoachHistoryModalVisible"
           @close="closeCoachHistoryModal"
           :isModalVisible="isCoachHistoryModalVisible">
      <h2 slot="header">User Coach History</h2>
      <div class="text-left row" slot="body">
        <div v-if="activeUser.id && isCoachHistoryModalVisible" class="w-100 m-3">
          <userCoachHistory :key="activeItemId" :user="activeUser" @dirty="formDirty($event)"></userCoachHistory>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import userRoles from '@components/admin/user-roles.vue';
import changeEmail from '@components/dashboard-items/management/change-email';
import userRecordsView from '@components/dashboard-items/management/user-records-view';
import userCoachHistory from '@components/dashboard-items/management/user-coach-history';
import { mapGetters } from 'vuex';
import { locale, getText } from '@scripts/localized';
export default {
  name: 'ManageUsers',
  components: {
    userRoles,
    changeEmail,
    userRecordsView,
    userCoachHistory
  },
  data() {
    return {
      locale: locale,
      userEntityType: 1,
      userFormId: 1,
      entityTypeName: 'User',
      additionalActions: [
        {
          title: 'Change Email Address',
          icon: 'address-card',
          click: this.changeEmailAddress,
          check: this.shouldShowChangeEmail
        },
        {
          title: 'Confirm User',
          icon: 'unlock',
          click: this.confirmUser,
          check: this.isUserUnconfirmed
        },
        {
          title: 'User Access Permissions',
          icon: 'user-lock',
          click: this.manageAccess,
          check: this.shouldShowPermissionsLink
        },
        {
          title: 'User Records',
          icon: 'clipboard-list',
          click: this.viewUserRecord,
          check: this.shouldShowUserRecordLink
        },
        {
          title: 'Coaching History',
          icon: 'people-arrows',
          click: this.viewCoachHistory,
          check: this.shouldShowCoachHistoryLink
        },
        {
          title: 'Unlock User',
          icon: 'unlock',
          click: this.unlockUser,
          check: this.isUserLocked
        }
      ],
      isModalVisible: false,
      isChangeEmailModalVisible: false,
      isUserRecordModalVisible: false,
      isCoachHistoryModalVisible: false,
      isDirty: false,
      activeId: '',
      gridKey: '',
      activeItemId: '',
      disabled: false,
      activeUser: {},
      isBusy: false,
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  computed: {
    ...mapGetters(['profile', 'roles', 'isAuthenticated']),
    hasAdminRole: function () {
      if (this.roles && this.roles.length > 0 && this.roles.includes('AdminUser')) return true;
      return false;
    },
    hasSubAdminRole: function () {
      if (this.roles && this.roles.length > 0 && (this.roles.includes('400') || this.roles.includes('500'))) return true;
      return false;
    },
    has500Role: function () {
      if (this.roles && this.roles.length > 0 && this.roles.includes('500')) return true;
      return false;
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    manageAccess(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeUser = item;
        this.showModal();
      }
    },
    viewUserRecord(item) {
      if (item && item.id) {
        this.activeItemId = item.id;
        this.activeUser = item;
        this.showUserRecordModal();
      }
    },
    changeEmailAddress(item) {
      if (item && item.id) {
        this.activeItemId = item.id;
        this.activeUser = item;
        this.showChangeEmailModal();
      }
    },
    viewCoachHistory(item) {
      if (item && item.id) {
        this.activeItemId = item.id;
        this.activeUser = item;
        this.showCoachHistoryModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    showChangeEmailModal() {   
      this.isBusy = true;
      this.isChangeEmailModalVisible = true;
    },
    showUserRecordModal() {
      this.isUserRecordModalVisible = true;
    },
    showCoachHistoryModal() {
      this.isCoachHistoryModalVisible = true;
    },
    async closeModal(type) {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.activeId = 'closed';
        this.activeUser = {};
      }
    },
    async closeChangeEmailModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isChangeEmailModalVisible = false;
        this.activeId = 'closed';
        this.activeUser = {};
        this.isBusy = false;
      }
    },
    async closeUserRecordModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isUserRecordModalVisible = false;
        this.activeItemId = 'closed';
        this.activeUser = {};
      }
    },
    async closeCoachHistoryModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isCoachHistoryModalVisible = false;
        this.activeItemId = 'closed';
        this.activeUser = {};
      }
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowPermissionsLink(item) || this.shouldShowUserRecordLink(item);
    },
    shouldShowPermissionsLink(item) {
      
      return this.hasAdminRole || item.id == this.profile.id || !item.hasElevatedRole || (this.has500Role && !item.has500OrHigherRole);
    },
    shouldShowUserRecordLink(item) {
      
      return this.hasAdminRole || item.id == this.profile.id || !item.hasElevatedRole || (this.has500Role && !item.has500OrHigherRole);
    },
    shouldShowChangeEmail(item) {
      return ['tn','al'].includes(this.locale) && (this.hasSubAdminRole || this.hasAdminRole);
    },
    shouldShowCoachHistoryLink(item) {
      return item.showCoachHistoryLink ? true : false;
    },
    isUserLocked(item) {
      return item.userLocked ? true : false;
    },
    unlockUser(item) {
      const body = {
        userId: item.id
      };
      this.isBusy = true;
      this.$store
        .dispatch('unlockUserRequest', body)
        .then(async (response) => {
          this.isBusy = false;
          if (this.$refs.userGrid && this.$refs.userGrid.loadPage && this.$refs.userGrid.currentPage)
            this.$refs.userGrid.loadPage(this.$refs.userGrid.currentPage);
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    isUserUnconfirmed(item) {
      return item.isConfirmed ? false : true;
    },
    async confirmUser(item) {
      return await new Promise((resolve, reject) => {
        if (this.isUserUnconfirmed(item)) {
          const options = {
            title: `Confirm User ${item.firstName} ${item.lastName}`,
            cancelLabel: 'Cancel',
            okLabel: 'Confirm'
          };
          this.$dialogs
            .confirm(`Are you sure you want to confirm ${item.firstName} ${item.lastName} and the associated email address ${item.userName}? If so, please click Confirm.`, options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                const body = { userName: item.userName };
                this.$store
                  .dispatch('emailConfirmRequest', body)
                  .then(async (response) => {
                    this.isBusy = false;
                    const opts = { title: `${item.firstName} ${item.lastName} is confirmed!`};
                    this.$dialogs.alert(`You have successfully confirmed ${item.firstName} ${item.lastName} and the associated email address ${item.userName}.<br /><br '>Thanks,<br />${getText('textID')} Team`, opts);
                    if (this.$refs.userGrid && this.$refs.userGrid.loadPage && this.$refs.userGrid.currentPage)
                      this.$refs.userGrid.loadPage(this.$refs.userGrid.currentPage);
                  })
                  .catch((errors) => {
                    this.isBusy = false;
                  });          
              }
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    },
  }
};
</script>
