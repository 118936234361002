<template>
  <section class="container-fluid">
    <div class="profile-form-container">
      <div class="mb-4 text-center">
        <h1>Coach Information</h1>
      </div>
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle.requests>
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon :icon="requestsOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
              </button>
              <span class="ml-4">Requests</span>
            </span>
          </b-card-header>
          <b-collapse id="requests" accordion="coaches-accordion" role="tabpanel" v-model="requestsOpen">
            <b-card-body style="padding: 0px">
              <requestDetails
                @saved="requestSaved"
                :initialLoad="initialLoad"
                @loaded="requestsLoaded($event)"
              ></requestDetails>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle.coaches>
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon :icon="coachesOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
              </button>
              <span class="ml-4">Coaches</span>
            </span>
          </b-card-header>
          <b-collapse id="coaches" accordion="coaches-accordion" role="tabpanel" v-model="coachesOpen">
            <b-card-body style="padding: 0px">
              <grid
                :entityTypeId="coachRelationshipEntityTypeId"
                :formId="0"
                :entityTypeName="''"
                :showFormOnEmpty="false"
                :showAddButton="false"
                :primaryEntity="getPrimaryEntity()"
                :showDisable="false"
                :showEdit="false"
                :showView="false"
                :allowDownload="false"
                ref="coachesGrid"
                :additionalShowColumns="additionalColumns"
                :additionalActions="additionalActions"
                :additionalActionsOnColumn="true"
                v-if="coachesOpen"
                :basePageSize="gridPageSize"
                :baseCurrentPage="gridCurrentPage"
                @setPageSize="setPageSize"
                @setCurrentPage="setCurrentPage"
              ></grid>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import requestDetails from './coach-requests.vue';
import { mapGetters } from 'vuex';
import RequestPromptRequired from '../utility/dialog/request-prompt-required';
export default {
  name: 'Coaches',
  components: { requestDetails },
  data() {
    return {
      requestsOpen: true,
      coachesOpen: false,
      userEntityTypeId: 1,
      coachRelationshipEntityTypeId: 21,
      additionalColumns: ['coachName'],
      additionalActions: [
        {
          title: 'End Coaching',
          icon: 'user-minus',
          click: this.closeCoach,
          check: this.shouldShowCloseLink
        },
        {
          title: 'Enable ability to comment on PDP',
          icon: 'handshake',
          click: this.sharePdp,
          check: this.shouldShowSharePdpLink
        },
        {
          title: 'Remove ability to comment on PDP',
          icon: 'handshake-slash',
          click: this.removePdpSharing,
          check: this.shouldShowRemovePdpSharingLink
        }
      ],
      actionStatuses: {
        requested: 1,
        approved: 2,
        rejected: 3,
        closed: 4,
        withdrawn: 5,
        pdpShared: 6,
        pdpShareRemoved: 7
      },
      initialLoad: true,
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  computed: {
    ...mapGetters(['userId'])
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    getPrimaryEntity() {
      return {
        Id: this.userId,
        EntityTypeId: this.userEntityTypeId
      };
    },
    requestSaved() {
      if (this.$refs.coachesGrid && this.$refs.coachesGrid.loadSummary) this.$refs.coachesGrid.loadSummary();
    },
    shouldShowCloseLink(item) {
      return item.active;
    },
    shouldShowSharePdpLink(item) {
      return item.active && !item.pdpShared;
    },
    shouldShowRemovePdpSharingLink(item) {
      return item.active && item.pdpShared;
    },
    async closeCoach(item) {
      const action = this.actionStatuses.closed;
      const title = 'Confirm End Coaching';
      const text =
        'Are you sure you want to end the coaching' + (item.coachName ? ' with ' + item.coachName : '') + '?';
      await this.performAction(action, item.id, title, text);
    },
    async sharePdp(item) {
      const action = this.actionStatuses.pdpShared;
      const title = 'Confirm Enable PDP Commenting';
      const text =
        'Are you sure you want enable ' +
        (item.coachName ? item.coachName + "'s " : '') +
        ' ability to comment on your PDP?';
      await this.performAction(action, item.id, title, text);
    },
    async removePdpSharing(item) {
      const action = this.actionStatuses.pdpShareRemoved;
      const title = 'Confirm Disable PDP Commenting';
      const text =
        'Are you sure you want disable ' +
        (item.coachName ? item.coachName + "'s " : '') +
        ' ability to comment on your PDP?';
      await this.performAction(action, item.id, title, text);
    },
    async performAction(action, itemId, promptTitle, promptText) {
      return await new Promise((resolve, reject) => {
        promptText = promptText + ' If so, please enter a comment and click Continue.';
        const options = {
          title: promptTitle,
          okLabel: 'Continue',
          cancelLabel: 'Cancel',
          prompt: {
            invalidMessage: '',
            component: RequestPromptRequired
          }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            //if we clicked continue
            if (res && res.ok && res.ok == true) {
              const body = {
                coachRelationshipId: itemId,
                action: action,
                comments: res.value
              };
              this.isBusy = true;
              this.$store
                .dispatch('coachRelationshipActionRequest', body)
                .then(async (response) => {
                  this.isBusy = false;
                  if (this.$refs.coachesGrid && this.$refs.coachesGrid.loadSummary)
                    await this.$refs.coachesGrid.loadSummary();
                  resolve(true);
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            }
            //we closed the window
            resolve(false);
          })
          .catch((error) => reject(error));
      });
    },
    requestsLoaded(value) {
      this.initialLoad = false;
      this.requestsOpen = value > 0;
      this.coachesOpen = !this.requestsOpen;
    }
  }
};
</script>
