<template>
  <section class="container-fluid">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div :class="isBusy ? 'disabled-area has-text-centered' : 'has-text-centered'">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Reports</h1>
          <p>
            Select your {{ $t('message.facility').toLowerCase() }} prior to choosing and running Staff Reports for
            download in Excel.
          </p>
        </div>
        <div class="row mb-4">
          <div class="col">
            <b-dropdown
              :text="filterTitle"
              variant="wfr"
              class="mr-2"
              v-if="!admin"
              :disabled="
                (activeRole.organizations && activeRole.organizations.length > 1) || !filterOrganizationId
                  ? false
                  : true
              "
            >
              <b-dropdown-item-button v-if="filterOrganizationId" @click="setFilterOrganization(null)">
                Clear Filter
              </b-dropdown-item-button>
              <b-dropdown-item-button
                v-for="organization in activeRole.organizations"
                :key="organization.organizationId"
                :active="filterOrganizationId == organization.organizationId"
                @click="setFilterOrganization(organization)"
              >
                {{ organization.organizationName }}
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col"></div>
          <div class="col-12 col-sm-11 col-md-10 col-lg-7">
            <div class="row">
              <div class="col-12 col-lg-7">
                <b-form-group label="Staff Reports:">
                  <b-form-checkbox-group
                    id="cbGrpStaffReports"
                    v-model="reportsSelected"
                    :options="options"
                    class="ml-5"
                    stacked
                  >
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-5 mt-3 mt-lg-0">
                <b-form-group label="Report Format:">
                  <b-form-radio-group id="rbGrpFormat" v-model="formatSelected" class="ml-5" stacked>
                    <b-form-radio name="rbCSV" value="csv"
                      ><icon icon="file-csv" size="lg" class="mr-1"></icon>CSV</b-form-radio
                    >
                    <b-form-radio disabled name="rbPDF" value="pdf"
                      ><icon icon="file-pdf" size="lg" class="mr-1"></icon>PDF</b-form-radio
                    >
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-12 mt-3 mt-lg-0 text-center">
                <b-button
                  variant="wfr"
                  @click="downloadReports"
                  :disabled="reportsSelected.length == 0 || formatSelected.length == 0 || !filterOrganizationId"
                  >Run Report<span v-if="reportsSelected.length > 1">s</span></b-button
                >
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { getText } from '@scripts/localized';
import { locale } from '@scripts/localized'

export default {
  name: 'DirectorReports',
  components: {},
  data() {
    return {
      isBusy: false,
      filterOrganizationId: '',
      options: [
        { text: 'Staff Education Records', value: '1' },
        { text: 'Staff Certification/Credential Records', value: '2' },
        { text: 'Staff Training Records', value: '3' },
        { text: 'Staff PDP Required Trainings', value: '4' },
        { text: 'Staff PDP Goals', value: '5' },
        { text: 'Staff Career Lattice Level Records', value: '6' }
      ],
      reportsSelected: [],
      formatSelected: 'csv',
      organizationEntity: {}
    };
  },
  props: {
    admin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['activeRole']),
    filterTitle: function () {
      if (this.activeRole && this.activeRole.organizations && this.filterOrganizationId) {
        const filterOrg = this.activeRole.organizations.find((org) => org.organizationId == this.filterOrganizationId);
        if (filterOrg && filterOrg.organizationName) return filterOrg.organizationName;
      }
      return `${getText('facility')} Filter`;
    }
  },
  created() {
    //if we only have one org, set it as the active value in the dropdown, which will be disabled
    if (['al'].includes(locale)) {
      this.options.splice(3, 2);
    }
    if (['tn'].includes(locale)) {
      this.options.splice(5, 1);
    }
    if (
      this.activeRole &&
      this.activeRole.organizations &&
      this.activeRole.organizations.length == 1 &&
      this.activeRole.organizations[0] &&
      this.activeRole.organizations[0].organizationId
    ) {
      const activeOrg = this.activeRole.organizations[0];
      this.filterOrganizationId = activeOrg.organizationId;
      this.organizationEntity = activeOrg;
    }
  },
  methods: {
    setFilterOrganization(organization) {
      if (organization && organization.organizationId) {
        this.filterOrganizationId = organization.organizationId;
        this.organizationEntity = organization;
      } else {
        this.filterOrganizationId = '';
        this.organizationEntity = {};
      }
    },
    async downloadReports() {
      for (let i = 0; i < this.reportsSelected.length; i++) {
        const report = this.options.find((report) => report.value == this.reportsSelected[i]);
        if (report) {
          await this.downloadReport(report);
        }
      }
      this.reportsSelected = [];
    },
    async downloadReport(report) {
      if (!this.filterOrganizationId) {
        const options = { title: 'Error' };
        this.$dialogs.alert(`Please select an ${getText('agency').toLowerCase()} from the dropdown menu`, options);
        return;
      }
      return await new Promise((resolve, reject) => {
        const type = report.text.replace(new RegExp(' ', 'g'), '').replace('/','');
        const body = {
          reportType: type,
          organizationId: this.filterOrganizationId,
          fileType: this.formatSelected
        };
        this.isBusy = true;
        this.$store
          .dispatch('directorReportRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            if (response && response.report) {
              const fileName = this.$timestampedReportName(
                this.organizationEntity.organizationName,
                report.text,
                this.formatSelected
              );
              if (this.formatSelected == 'csv') await this.saveCsv(fileName, response.report);
              if (this.formatSelected == 'pdf') await this.savePdf(fileName, response.report);
              resolve(true);
            }
            resolve(false);
          })
          .catch((errors) => {
            this.isBusy = false;
            reject(errors);
          });
      });
    },
    async saveCsv(filename, data) {
      return await new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: 'text/csv' });
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
        resolve(true);
      });
    },
    async savePdf(strFileName, strData) {
      return await new Promise((resolve, reject) => {
        const pdfData = 'data:application/pdf;base64,' + escape(strData);
        const link = document.createElement('a');
        link.href = pdfData;
        link.download = strFileName;
        link.click();
        resolve(true);
      });
    }
  }
};
</script>
