<template>
  <div :class="isBusy ? 'disabled-area' : ''">
    <p class="text-left" style="color: #333">Current Email Address: {{ user.userName }}</p>
    <p />
    <div class="text-left" style="color: #333">
      <div class="form-row">
        <label class="col-md-4">Please enter the new email address: </label>
        <input class="col-md-8 input form-control" type="email" placeholder="myname@example.com" v-model="newEmail" />
      </div>
      <p />
      <button aria-label="Cancel" class="btn v-dialog-btn-close mr-3" style="border-color: #999" @click="closeModal">Cancel</button>
      <button aria-label="Continue" class="btn btn-primary" @click="launchDialog">Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeEmail',
  props: {
    user: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      isBusy: false,
      isDirty: false,
      newEmail: '',
      currentEmail: '',
      userName: '',
    };
  },
  watch: {
    isBusy: function (newValue, oldValue) {
      this.$emit('busy', newValue);
    },
  },
  created() {
    if (this.user) {
      this.userName = this.user.fullName;
      this.currentEmail = this.user.userName;
    }
  },
  methods: {
    closeModal() {
      if (this.isDirty) {
        this.$parent.$parent.$parent.gridKey += '-1';
        this.isDirty = false;
      }
      this.$parent.$parent.$emit('close');
      
    },
    async launchDialog() {
      this.isBusy = true;
      return await new Promise((resolve, reject) => {
        const regText = `Are you sure to change ${this.userName}'s email address from ${this.currentEmail} to ${this.newEmail}? If so, please click confirm. User will be notified of this change.`;
        const regOptions = {
          title: 'Confirm Email Change',
          okLabel: 'Continue',
          cancelLabel: 'Cancel',
        };
        this.$dialogs
          .confirm(regText, regOptions)
          .then((res) => {
            if (res && res.ok && res.ok == true) {
              this.isDirty = true;
              return new Promise((resolve, reject) => {
                const body = {
                  email: this.newEmail.trim(),
                  userName: this.currentEmail.trim(),
                  fullName: this.userName.trim(),
                };
                this.$store
                  .dispatch('emailChangeRequest', body)
                  .then(async (response) => {
                    const alertOptions = { title:'Email Address Change is Complete!' };
                    this.$dialogs.alert(`You have successfully changed ${this.user.fullName}'s email address to ${this.newEmail}. User will be notified shortly.`, alertOptions);
                    resolve(true);
                  })
                  .catch((errors) => {
                    resolve(false);
                  });
                this.isBusy = false;
                this.closeModal();
            });
          }
        })
        .catch((error) => {
          resolve(false);
        });
        this.isBusy = false;
      });
    },
  }
};
</script>
