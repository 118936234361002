<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Active Cases</h1>
        </div>
        <div>
          <grid
            :entityTypeId="coachRelationshipEntityTypeId"
            :formId="20"
            :entityTypeName="'Coach Relationship'"
            :showFormOnEmpty="false"
            :showAddButton="false"
            :primaryEntity="getPrimaryEntity()"
            :showDisable="false"
            :showEdit="false"
            :showView="false"
            :allowDownload="true"
            ref="coachesGrid"
            :additionalShowColumns="additionalColumns"
            :additionalActions="additionalActions"
            :additionalActionsOnColumn="true"
            :defaultFilters="defaultFilters"
            :exportFilters="exportFilters"
            :rowClick="viewUserRecords"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          ></grid>
        </div>
      </div>
    </div>
    <modal
      v-show="isUserRecordsModalVisible"
      @close="closeUserRecordsModal"
      :isModalVisible="isUserRecordsModalVisible"
    >
      <h2 slot="header">User Records - {{ activeUser.fullName }}</h2>
      <div class="text-left row" slot="body">
        <div v-if="activeUser.id" class="w-100 m-3">
          <userRecordsView
            :key="activeItemId"
            :user="activeUser"
            @dirty="formDirty($event)"
            :relationshipPdpCommenting="relationshipPdpCommenting"
          ></userRecordsView>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import RequestPromptRequired from '../../utility/dialog/request-prompt-required';
import userRecordsView from './user-records-view';
export default {
  name: 'ActiveCases',
  components: {
    userRecordsView
  },
  data() {
    return {
      userEntityTypeId: 1,
      coachRelationshipEntityTypeId: 21,
      additionalColumns: ['primaryEntityName'],
      defaultFilters: [
        {
          operation: 'Equals',
          filterValue: 'true',
          filterColumn: { propertyName: 'Active' }
        }
      ],
      exportFilters: [
        {
          operation: 'Contains',
          filterValue: 'Active',
          filterColumn: { propertyName: 'status' }
        }
      ],
      additionalActions: [
        {
          title: 'User Records',
          icon: 'clipboard-list',
          click: this.viewUserRecords,
          check: this.shouldShowUserRecordLink
        },
        {
          title: 'End Coaching',
          icon: 'user-minus',
          click: this.closeCoach,
          check: this.shouldShowCloseLink
        }
      ],
      actionStatuses: {
        requested: 1,
        approved: 2,
        rejected: 3,
        closed: 4,
        withdrawn: 5,
        pdpShared: 6,
        pdpShareRemoved: 7
      },
      isDirty: false,
      isUserRecordsModalVisible: false,
      activeItemId: '',
      activeUser: {},
      relationshipPdpCommenting: false,
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  computed: {
    ...mapGetters(['userId', 'isAuthenticated'])
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    getPrimaryEntity() {
      return {
        Id: this.userId,
        EntityTypeId: this.userEntityTypeId
      };
    },
    shouldShowUserRecordLink(item) {
      return item.active;
    },
    shouldShowCloseLink(item) {
      return item.active;
    },
    async closeCoach(item) {
      const action = this.actionStatuses.closed;
      const title = 'End Coaching';
      const text =
        'Are you sure you want to end coaching' + (item.primaryEntityName ? ' ' + item.primaryEntityName : '') + '?';

      await this.performAction(action, item.id, title, text);
    },
    async performAction(action, itemId, promptTitle, promptText) {
      return await new Promise((resolve, reject) => {
        promptText = promptText + ' If so, please enter a comment and click Continue.';
        const options = {
          title: promptTitle,
          okLabel: 'Continue',
          cancelLabel: 'Cancel',
          prompt: {
            invalidMessage: '',
            component: RequestPromptRequired
          }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            //if we clicked continue
            if (res && res.ok && res.ok == true) {
              const body = {
                coachRelationshipId: itemId,
                action: action,
                comments: res.value
              };
              this.isBusy = true;
              this.$store
                .dispatch('coachRelationshipActionRequest', body)
                .then(async (response) => {
                  this.isBusy = false;
                  if (this.$refs.coachesGrid && this.$refs.coachesGrid.loadSummary)
                    await this.$refs.coachesGrid.loadSummary();
                  resolve(true);
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            }
            //we closed the window
            resolve(false);
          })
          .catch((error) => reject(error));
      });
    },
    viewUserRecords(item) {
      if (item && item.primaryEntityId && item.active) {
        this.activeItemId = item.primaryEntityId;
        this.relationshipPdpCommenting = item.pdpShared;
        this.activeUser = {
          id: item.primaryEntityId,
          fullName: item.primaryEntityName
        };
        this.showUserRecordsModal();
      }
    },
    showUserRecordsModal() {
      this.isUserRecordsModalVisible = true;
    },
    async closeUserRecordsModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isUserRecordsModalVisible = false;
        this.relationshipPdpCommenting = false;
        this.activeItemId = 'closed';
        this.activeUser = {};
      }
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    }
  }
};
</script>
