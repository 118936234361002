<template>
  <div :class="isBusy ? 'disabled-area ' : ''">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div>
      <div class="mb-4">
        <b-button
          :variant="variant100"
          :disabled="canSubmit100 ? false : true"
          :title="title100button"
          @click="request100"
          >Request staff role</b-button
        >
        <b-button
          class="ml-3"
          :variant="variant200"
          v-if="has100"
          :disabled="canSubmit200 ? false : true"
          :title="title200button"
          @click="request200"
          >Request director role</b-button
        >
      </div>
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle.requests>
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon :icon="requestsOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
              </button>
              <span class="ml-4">Requests</span>
            </span>
          </b-card-header>
          <b-collapse id="requests" accordion="access-accordion" role="tabpanel" v-model="requestsOpen">
            <b-card-body>
              <b-table
                hover
                :items="requestTableRows"
                v-if="userOrgRoleInfo.requests && userOrgRoleInfo.requests.length > 0"
              >
              </b-table>
              <span v-else>No Requests</span>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'EmploymentRoles',
  data() {
    return {
      isBusy: false,
      userOrgRoleInfo: [],
      actionTypes: {
        requested: {
          id: 1,
          display: 'Requested'
        },
        approved: {
          id: 2,
          display: 'Approved'
        },
        rejected: {
          id: 3,
          display: 'Rejected'
        },
        removed: {
          id: 4,
          display: 'Removed'
        },
        withdrawn: {
          id: 10,
          display: 'Withdrawn'
        }
      },
      requestsOpen: false,
      pendingTitle: 'There is a current request pending, view the status in the requests table.'
    };
  },
  props: {
    employment: {
      type: Object,
      default: () => {}
    },
    submit100Request: {
      type: Function,
      default: () => {
        return true;
      }
    },
    submit200Request: {
      type: Function,
      default: () => {
        return true;
      }
    },
    roles: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.loadInfo();
  },
  computed: {
    ...mapGetters(['rolePromotionWarning']),
    canSubmit100: function () {
      if (this.userOrgRoleInfo) {
        //if they already have 100 role, can't submit again
        if (this.has100) return false;
        //if they don't have any requests yet, return true
        if (!this.userOrgRoleInfo.requests || this.userOrgRoleInfo.requests.length == 0) return true;
        //if none of their requests are in a submitted state return true
        if (!this.hasPending100) return true;
      }
      return false;
    },
    has100: function () {
      if (this.userOrgRoleInfo && this.userOrgRoleInfo.active && this.userOrgRoleInfo.active.roles)
        return this.userOrgRoleInfo.active.roles.some((role) => role.roleName == this.roles.role100.name);
      return false;
    },
    hasPending100: function () {
      return this.requests100.some(
        (request) =>
          request.actionType == this.actionTypes.requested.id &&
          !this.requests100.some(
            (r) => r.actionType != this.actionTypes.requested.id && r.parentActionId == request.rolePromotionActionId
          )
      );
    },
    requests100: function () {
      if (this.userOrgRoleInfo && this.userOrgRoleInfo.requests)
        return this.userOrgRoleInfo.requests.filter(
          (request) => request.roleRequested.roleName == this.roles.role100.name
        );
      return [];
    },
    title100button: function () {
      if (this.hasPending100) return this.pendingTitle;
      if (this.has100) return 'Staff level access already granted';
      if (this.canSubmit100) return 'Submit a request to have your employment record authenticated by the organization';
      return '';
    },
    variant100: function () {
      if (this.canSubmit100) return 'secondary';
      if (this.has100) return 'has100';
      if (this.hasPending100) return 'hasPending100';
      return 'outline-secondary';
    },
    canSubmit200: function () {
      if (this.userOrgRoleInfo && this.userOrgRoleInfo.active && this.userOrgRoleInfo.active.roles) {
        //if they don't already have 100 they can't go for 200
        if (!this.has100) return false;
        //if they already have 200 role, can't submit again
        if (this.has200) return false;
        //if none of their 200 requests are in a submitted state return true
        if (!this.hasPending200) return true;
      }
      return false;
    },
    has200: function () {
      if (this.userOrgRoleInfo && this.userOrgRoleInfo.active && this.userOrgRoleInfo.active.roles)
        return this.userOrgRoleInfo.active.roles.some((role) => role.roleName == this.roles.role200.name);
      return false;
    },
    hasPending200: function () {
      return this.requests200.some(
        (request) =>
          request.actionType == this.actionTypes.requested.id &&
          !this.requests200.some(
            (r) => r.actionType != this.actionTypes.requested.id && r.parentActionId == request.rolePromotionActionId
          )
      );
    },
    requests200: function () {
      if (this.userOrgRoleInfo && this.userOrgRoleInfo.requests)
        return this.userOrgRoleInfo.requests.filter(
          (request) => request.roleRequested.roleName == this.roles.role200.name
        );
      return [];
    },
    title200button: function () {
      if (this.hasPending200) return this.pendingTitle;
      if (!this.has100) return 'Must be granted Staff level access before Director level access can be requested';
      if (this.has200) return 'Director level access already granted';
      if (this.canSubmit200) return 'Submit a request to become a Director level user for your organization';
      return '';
    },
    variant200: function () {
      if (this.canSubmit200) return 'secondary';
      if (this.has200) return 'has200';
      if (this.hasPending200) return 'hasPending200';
      return 'outline-secondary';
    },
    requestTableRows: function () {
      const requests = [];
      if (this.userOrgRoleInfo && this.userOrgRoleInfo.requests) {
        this.userOrgRoleInfo.requests.forEach((request) => {
          requests.push({
            requestType: request.roleRequested.roleName,
            actionType: this.getActionTypeDisplayTextById(request.actionType),
            comments: request.comments,
            lastUpdated: this.$formatUtcDate(request.lastChangeDateTime),
            lastUpdatedBy: request.userLastUpdated.name
          });
        });
      }
      return requests;
    }
  },
  methods: {
    loadInfo() {
      if (this.employment && this.employment.id) {
        const body = {
          employmentRecordId: this.employment.id
        };
        this.isBusy = true;
        this.$store
          .dispatch('userOrganizationRolesRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.userOrgRoleInfo = response;
          })
          .catch((errors) => {
            this.isBusy = false;
          });
      }
    },
    getActionTypeDisplayTextById(id) {
      return this.actionTypes[Object.keys(this.actionTypes).find((key) => this.actionTypes[key].id == id)].display;
    },
    async request100() {
      this.isBusy = true;
      await this.submit100Request(this.employment);
      this.isBusy = false;
      this.loadInfo();
    },
    async request200() {
      this.isBusy = true;
      await this.submit200Request(this.employment);
      this.isBusy = false;
      this.loadInfo();
    }
  }
};
</script>
