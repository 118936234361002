var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'form-group col-md-' + _vm.width + (_vm.offset ? ' col-md-offset-right-' + _vm.offset : '')},[(_vm.stimulus)?_c('div',[_c('label',[_vm._v(_vm._s(_vm.stimulus))]),(_vm.required)?_c('span',{staticClass:"required-red"},[_vm._v("*")]):_vm._e(),(_vm.tooltip)?_c('a',{staticClass:"mouse-pointer mx-1 form-tooltip"},[_c('icon',{staticClass:"form-tooltip-icon",attrs:{"icon":'question-circle',"size":'sm'}}),_c('span',{staticClass:"form-tooltiptext"},[_vm._v(_vm._s(_vm.tooltip))])],1):_vm._e()]):_vm._e(),_c('auto-complete',{attrs:{"items":_vm.items,"loading":_vm.isBusy,"item-text":"value","placeholder":_vm.stimulus,"disable-filtering-by-search":"","disabled":_vm.readOnly ? true : false,"value":_vm.value,"input-el-custom-attributes":{
      required: _vm.required,
      formelementid: _vm.id,
      autocomplete: 'new-password',
      id: _vm.controlIdFinal
    }},on:{"search":_vm.onSearch,"focus":_vm.onFocus},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.value))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"input-start"},[_c('icon',{staticClass:"ml-2",attrs:{"icon":"search","size":"xs"}})],1),_c('template',{slot:"no-data"},[_c('span',[_vm._v(_vm._s(_vm.noData ? 'No matches found.' : _vm.warningMessage))])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }