<template>
  <section>
    <div class="container">
      <div id="maintenance" style="margin-top: 150px">
        <div class="row align-items-center">
          <div class="col text-center">
            <img src="../../../images/logo/alp_logo.jpg" alt="Registry 360 Logo" style="width: 345px" />
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col-9 col-lg-6">
            <p>
              &nbsp;<br/>
              &nbsp;<br/>
              Welcome to Alabama Pathways, the Alabama Department of Human Resources’ 
              Professional Development Registry site. 
            </p>
            <p>
              The pilot phase for Alabama Pathways has ended. 
              The full Registry site is being finalized and will be available soon! 
              Training will be provided to all licensed child care providers and their staff in the coming weeks. 
              As a reminder, participation in Alabama Pathways is a requirement of the Child Care Licensing and Performance Standards. 
              All providers and their staff will need to be enrolled in Alabama Pathways by September 1, 2022 to avoid deficiencies. 
            </p>
            <p>
              Thank You<br/>
              Alabama Pathways Team
            </p>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  }
};
</script>
