<template>
  <div>
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div>
      <grid
        :entityTypeId="entityTypeId"
        :formId="formId"
        :entityTypeName="entityTypeName"
        :primaryEntity="primaryentity"
        :showFormOnEmpty="true"
        :showContinueButton="true"
        @continueButton="$emit('continueButton')"
        @dirty="$emit('dirty', $event)"
        :additionalActions="additionalActions"
        :checkForAdditionalActions="shouldShowAdditionalActions"
        :key="gridKey"
        :additionalShowColumns="additionalColumns"
        :basePageSize="gridPageSize"
        :baseCurrentPage="gridCurrentPage"
        @setPageSize="setPageSize"
        @setCurrentPage="setCurrentPage"
      >
      </grid>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="this.isModalVisible">
      <h2 slot="header">
        Organizational Roles<span v-if="activeEmploymentRecord.employerName">
          - {{ activeEmploymentRecord.employerName }}</span
        >
      </h2>
      <employmentRoles
        slot="body"
        :key="activeId"
        @dirty="formDirty($event)"
        :employment="activeEmploymentRecord"
        :submit100Request="submit100Request"
        :submit200Request="submit200Request"
        :roles="roles"
      >
      </employmentRoles>
    </modal>
  </div>
</template>

<script>
import employmentRoles from '@components/employment-roles.vue';
import { mapGetters } from 'vuex';
import { locale } from '@scripts/localized';
export default {
  name: 'ProfileEmployment',
  components: {
    employmentRoles
    },
    created() {
      this.firstName = this._props.primaryentity.FirstName;
      this.lastName = this._props.primaryentity.LastName;
  },
  computed: {
  ...mapGetters(['rolePromotionWarning', 'userId', 'isAuthenticated'])
  },
  data() {
    return {
      entityTypeId: 8,
      formId: 6,
      firstName: '',
      lastName: '',
      entityTypeName: 'Experience',
      additionalActions: [
        {
          title: 'Training Report',
          icon: 'book',
          click: this.getReport,
          check: this.shouldShowReportLink
        },
        {
          title: 'Organizational Roles',
          icon: 'user-shield',
          click: this.manageRoles,
          check: this.shouldShowRolesLink
        },
        {
          title: 'Request Staff Role',
          icon: 'user-shield',
          click: this.submit100Request,
          check: this.shouldShow100Link
        },
        {
          title: 'End Director Role',
          icon: 'user-times',
          click: this.remove200,
          check: this.shouldShowRemove200Link
        },
        {
          title: 'End Staff Role',
          icon: 'user-times',
          click: this.remove100,
          check: this.shouldShowRemove100Link
        },
        {
          title: 'Remove TNTrain Access',
          icon: 'door-closed',
          click: this.removeTrainTNAccess,
          check: this.shouldShowTNTrainAccessLink
        }
      ],
      additionalColumns: ['startDate', 'endDate'],
      isModalVisible: false,
      isDirty: false,
      activeId: '',
      activeEmploymentRecord: {},
      isBusy: false,
      roles: {
        role100: {
          name: '100',
          display: 'Staff Level'
        },
        role200: {
          name: '200',
          display: 'Director Level'
        },
        role300: {
          name: '300',
          display: '300 Level'
        },
        role400: {
          name: '400',
          display: '400 Level'
        },
        role500: {
          name: '500',
          display: '500 Level'
        }
      },
      gridKey: 'employment',
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    manageRoles(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeEmploymentRecord = item;
        this.showModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    async closeModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.activeId = 'closed';
        this.activeEmploymentRecord = {};
      }
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    shouldShowAdditionalActions(item) {
      return (
        this.shouldShowReportLink(item) ||
        this.shouldShow100Link(item) ||
        this.shouldShowRolesLink(item) ||
        this.shouldShowRemove200Link(item) ||
        this.shouldShowRemove100Link(item) ||
        this.shouldShowTNTrainAccessLink(item)
      );
    },
    shouldShowRolesLink(item) {
      return item.isRegistryOrganization && !item.endDate && !this.shouldShow100Link(item);
    },
    shouldShow100Link(item) {
      return item.isRegistryOrganization && item.requestCount == 0 && !item.endDate && !item.has100Role;
    },
    shouldShowRemove200Link(item) {
      return item.isRegistryOrganization && item.has200Role;
    },
    shouldShowRemove100Link(item) {
      return item.isRegistryOrganization && item.has100Role && !item.has200Role;
    },
    shouldShowReportLink(item) {
      return locale === 'tn'
        && item.isRegistryOrganization && item.has100Role;
    },
    shouldShowTNTrainAccessLink(item) {
      return locale === 'tn'
        && item.isRegistryOrganization && item.hasTrainTNAccess;
    },
    async submit100Request(item) {
      const confirmed = await this.userConfirmation('Request for a Staff Role', this.rolePromotionWarning);
      if (!confirmed) return;
      return await new Promise((resolve, reject) => {
        const body = {
          employmentRecordId: item.id,
          requestType: this.roles.role100.name
        };
        this.isBusy = true;
        this.$store
          .dispatch('rolePromotionRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.resetGrid();
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
          });
      });
    },
    async userConfirmation(titleText, text) {
      return await new Promise((resolve, reject) => {
        const options = {
          title: titleText,
          cancelLabel: 'Cancel'
        };
        this.$dialogs
          .confirm(text, options)
          .then((res) => {
            if (res && res.ok && res.ok == true) {
              resolve(true);
            }
            resolve(false);
          })
          .catch((error) => {
            resolve(false);
          });
      });
    },
    async submit200Request(item) {
      return await new Promise((resolve, reject) => {
        const body = {
          employmentRecordId: item.id,
          requestType: this.roles.role200.name
        };
        this.isBusy = true;
        this.$store
          .dispatch('rolePromotionRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.resetGrid();
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
          });
      });
    },
    async getReport(item) {
      this.isBusy = true;
      const body = { username: item.primaryEntityEmail, organizationId: item.organizationId };
      this.$store
        .dispatch('complianceReportRequest', body)
        .then((response) => {
          this.isBusy = false;
          if (response && response.file) {
            const employerName = this.strip(item.employerName);
            const fileName = `TNPAL_TrainingReport_${employerName}-${this.firstName + this.lastName}.pdf`;
            this.savePdf(fileName, response.file);
            const options = { title: 'Download successful.' };
            this.$dialogs.alert(`Check your Downloads folder for a PDF of the report.`, options);
          }
        })
        .catch((errors) => {
          this.isBusy = false;
          this.errors = errors;
        });
    },
    savePdf(strFileName, strData) {
      const pdfData = 'data:application/pdf;base64,' + escape(strData);

      const link = document.createElement('a');
      link.href = pdfData;
      link.download = strFileName;
      link.click();
    },
    strip(arr) {
      const arr2 = arr.split(" ");
      for (let item of arr2) {
        const index = arr2.indexOf(item);
        const ewc = item.endsWith(',');
        const ewp = item.endsWith('.');
        const e = item.length - 1;
        if (ewc || ewp) {
          item = item.slice(0, e);
          arr2[index] = item;
        }
        if (item.toLowerCase().includes('llc') || item.toLowerCase().includes('inc')) {
          arr2.splice(index, 1);
        }
      }
      return (arr2.join(" "));
    },
    resetGrid() {
      this.gridKey = Math.random().toString();
    },
    async remove200(item) {
      const title = 'End Director Role';
      let message = 'Are you sure you want to end your director role';
      if (item.employerName) {
        message = message + ' with ' + item.employerName;
      }
      message = message + '?';
      const confirmed = await this.userConfirmation(title, message);
      if (!confirmed) return;
      return await new Promise((resolve, reject) => {
        const body = {
          organizationId: item.organizationId,
          employmentRecordId: item.id,
          roleName: this.roles.role200.name,
          active: false,
          userId: this.userId
        };
        this.isBusy = true;
        this.$store
          .dispatch('userOrganizationRolesUpdateRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.resetGrid();
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
          });
      });
    },
    async removeTrainTNAccess(item) {
      const options = { title: 'Confirm TrainTN Access Removal', okLabel: 'Confirm', cancelLabel: 'Cancel' };
      this.$dialogs
        .confirm(`Are you sure you want to remove access to Train TN?`, options)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            const body = {
              employmentRecordId: item.id,
              accessStatus: false
            };
            this.isBusy = true;
            this.$store
              .dispatch('trainTNAccessRequest', body)
              .then(async (response) => {
                this.gridKey += '-1';
                this.isBusy = false;
              })
              .catch((errors) => {
                this.isBusy = false;
              });
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async remove100(item) {
      const title = 'End Staff Role';
      let message = 'Are you sure you want to end your staff role';
      if (item.employerName) {
        message = message + ' with ' + item.employerName;
      }
      message = message + '?';
      if (item.pending200) {
        message = message + ' Your pending director request will be automatically withdrawn.';
      }
      const confirmed = await this.userConfirmation(title, message);
      if (!confirmed) return;
      return await new Promise((resolve, reject) => {
        const body = {
          organizationId: item.organizationId,
          employmentRecordId: item.id,
          roleName: this.roles.role100.name,
          active: false,
          userId: this.userId
        };
        this.isBusy = true;
        this.$store
          .dispatch('userOrganizationRolesUpdateRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.resetGrid();
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
          });
      });
    }
  }
};
</script>
