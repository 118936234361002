<template>
  <div id="home">
    <!--For small screen only-->

    <div id="headline_text_holder">
      <div id="headline_text1">Choose your pathway to</div>
      <div id="headline_text2">Quality Child Care</div>
    </div>

    <!--END small screen only-->

    <div id="map1">


      <div id="header_holder">




        <div class="map_wrapper">
          <img id="map_1" class="map_image" style="position:relative;" src="../../../images/home/al/map_1.jpg">
          <img id="map_2" class="map_image" style="display:none;" src="../../../images/home/al/map_2.jpg">
          <img id="map_3" class="map_image" style="display:none;" src="../../../images/home/al/map_3.jpg">
          <img id="map_4" class="map_image" style="display:none;" src="../../../images/home/al/map_4.jpg">
          <img id="map_5" class="map_image" style="display:none;" src="../../../images/home/al/map_5.jpg">
          <img id="map_6" class="map_image" style="display:none;" src="../../../images/home/al/map_6.jpg">
        </div>


        <!--Button block-->
        <div class="home_banner_block">



          <a class="home_super_button" href="login/register">
            <span class="home_super_button_text_1">Create Account</span>
            <span class="home_super_button_text_2">Get started for free today!</span>
          </a>



          <a class="home_super_button" href="about">
            <span class="home_super_button_text_1">About Pathways</span>
            <span class="home_super_button_text_2">Features &amp; Benefits</span>
          </a>



        </div>
        <!--END button block-->
        <!--bg images-->
        <div id="bg_1" class="home_bg_image" style=""></div>
        <div id="bg_2" class="home_bg_image" style="display: none;"></div>
        <div id="bg_3" class="home_bg_image" style="display: none;"></div>
        <div id="bg_4" class="home_bg_image" style="display: none;"></div>
        <div id="bg_5" class="home_bg_image" style="display: none;"></div>
        <!--END bg images-->


      </div>


    </div>
    <!--END head holder-->
    <!--Page wrap-->
    <div class="home_page_wrap">

      <!--Body start-->
      <div id="home_body_holder">


        <div id="home_body">
          <div id="home_col_1">
            <h1>Welcome to Alabama Pathways!</h1>
            <h2 class="py-3">
              Alabama Pathways is a resource to provide a path to support and advance the best practices for those who care for and teach children from birth through 12 years.
            </h2>
            <div class="body_text">
              <ul>
                <li>Creating your own early childhood professional and training profile.</li>
                <li>Documenting your professional accomplishments and professional certificates.</li>
                <li>Identify your current place on the Alabama Pathways Professional Development Lattice.</li>
                <li>Collaborating with state-designated training agencies to help you identify and reach your goals.</li>
                <li>Linking to official training organizations.</li>
                <li>Access to available resources.</li>
              </ul>

              Your profile belongs to you. Take it along wherever your professional journey may take you. All you have to do is <a href="login">log in</a>.
            </div>

          </div>

          <!--col 2-->
          <div id="home_col_2">
            <div class="illustration_holder">
              <img src="../../../images/home/al/illustration_2.jpg" alt="">
              <div class="caption">
                Alabama Pathways provides powerful tools to create your own Early Childhood Professional training profile.
              </div>
            </div>
          </div>


        </div>
        <!--END body holder-->

      </div>
      <!--END body-->



    </div>
    <!--END page wrap-->
  </div>

</template>
<script>
  import { mapGetters } from 'vuex';
  import $ from 'jquery'
  export default {
    data() {
      return {};
    },
    computed: {
      ...mapGetters(['isAuthenticated'])
    },
    mounted() {
      $("#map_2").delay(1).fadeIn(2500);	//make sure there's no delay here to prevent jittering element bugginess
      $("#bg_2").delay(2000).fadeIn(2500);

      $("#map_3").delay(4000).fadeIn(2500);
      $("#bg_3").delay(6000).fadeIn(2500);

      $("#map_4").delay(8000).fadeIn(2500);
      $("#bg_4").delay(10000).fadeIn(2500);

      $("#map_5").delay(12000).fadeIn(2500);
      $("#bg_5").delay(14000).fadeIn(2500);

      $("#map_6").delay(16000).fadeIn(2500);
    }
  };
</script>
<style></style>
