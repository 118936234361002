<template>
  <section class="container-fluid">
    <div>
      <div class="text-right">
        <a class="light-blue-link d-inline-block" href="/api/file/s/pdp-help">
          PDP Help <icon icon="file-pdf" class="ml-1 fa-1_5x vaMiddle"></icon>
        </a>
      </div>
      <div class="mb-4 text-center">
        <h1>PDP Info</h1>
      </div>
      <div>
        <p>
          Your PDP is set up so you can reflect on the professional development you want and make a plan for getting
          what you need to grow as an early childhood professional. Collaborating with state-designated coaches may
          further help you identify and reach your goals.
        </p>
        <p>
          Your PDP is developed as each tab is completed. Once created, you can access and update your PDP throughout
          your career no matter where you are working in early childhood education!
        </p>
        <div class="mt-4 mb-1" style="max-width:383px;">
          <img class="img-fluid" src="../../../images/pages/SelfAssessmentTab.png" alt="Self Assesment tab" />
        </div>
        <p>
          The Self-Assessment Tab is where you think about what skills and knowledge you already have and consider what
          skills and knowledge you need to grow as an early childhood professional.
        </p>
        <p>
          The Core Content and Competency Areas below guide you to group what you know and the skills you want to
          develop. Click on each or select a few.
        </p>
        <div class="mt-4 mb-1" style="max-width:383px;">
          <img class="img-fluid" src="../../../images/pages/RequiredTrainingTab.png" alt="Required Training tab" />
        </div>
        <p>
          The Required Training Tab is where you will see {{ $t('message.pdpInfo') }} required training listed by each Core Content and
          Competency Area. When you click on the Core Content and Competency Area, the Topics of required training
          automatically appear. Notes show how many hours and how often training is required. If no note appears, this
          training may be required annually.
        </p>
        <div class="mt-4 mb-1" style="max-width:383px;">
          <img class="img-fluid" src="../../../images/pages/GoalsTab.png" alt="Goals tab" />
        </div>
        <p>
          The PDP Goals Tab is where you will write professional development short term goals and identify them by Core
          Content and Competency Area.
        </p>
        <p>
          Some of your goals may cross over several areas. What is your primary goal? What do you want to achieve? Ask
          yourself these questions and think about the strategies you will take and what you need to be successful.
        </p>
        <p>
          Working with your supervisor or a coach may help you set realistic, achievable, and measurable goals so you
          can see your progress.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PDPInfo',
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
