<template>
  <footer role="contentinfo" class="col">
    <div id="footer-info" class="row no-gutter">
      <div class="col-12 col-sm order-1 text-center mt-4 mt-sm-0">
        <div class="d-sm-inline-block text-left">
          <p class="font-weight-bold footer-title">Alabama Pathways</p>
          <div class="row mt-3">
            <div class="col-10">Contact our Help Desk:</div>
          </div>

          <div class="row">
            <div class="col-1">
              <icon icon="envelope" class="fa-1_5x" style="float: left"></icon>
            </div>
            <a class="col-10" href="mailto:ALPathways@dhr.alabama.gov">Send Email</a>
          </div>
          <!--added Contact Phone  -->
          <div class="row">
            <div class="col-1">
              <icon icon="phone-square-alt" class="fa-1_5x" style="float: left"></icon>
            </div>
            <a class="col-10" href="tel:334-353-9618">334-353-9618</a>
          </div>
          <!--added Time  -->
          <div class="row mt-3">
            <div class="col-1"></div>
            <div class="col-10 footer-text-small" style="padding-top: 0">9am - 4pm Central</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm order-2 text-center">
        <div class="d-sm-inline-block text-left mt-4 mt-sm-0">
          <p class="mt-2 mt-sm-0 font-weight-bold footer-title">Resources</p>
          <a href="https://www.alabamaqualitystars.org" target="_blank">Alabama Quality STARS</a>
          <a
            href="#"
            class="mt-2 d-block"
             @click.prevent="showReportModal()"
            >Alabama Pathways<icon icon="file-pdf" class="ml-2 fa-1_5x align-middle"></icon
          ></a>
          <a href="https://dhr.alabama.gov/wp-content/uploads/2019/07/AELG.pdf" target="_blank" class="mt-2 d-block"
            >Alabama Early Learning Guidelines<icon
              icon="file-pdf"
              class="ml-2 fa-1_5x align-middle position-absolute"
            ></icon
          ></a>
          <a href="#" class="mt-2 d-block" @click.prevent="showLegendModal()"
            >Training Legend<icon icon="file-pdf" class="ml-2 fa-1_5x align-middle"></icon
          ></a>
          <a href="#" class="mt-2 d-block" @click.prevent="showFaqModal()"
            >FAQ<icon icon="file-pdf" class="ml-2 fa-1_5x align-middle"></icon
          ></a>
        </div>
      </div>
      <div class="col order-5 order-xl-3 text-center mt-4 mt-lg-0">
        <img
          id="footer-logo"
          src="../../../images/logo/al_dhr_logo.png"
          alt="Alabama Department of Human Resources logo"
        />
      </div>
      <div class="col-12 col-sm order-3 order-xl-4 text-center">
        <div class="d-sm-inline-block text-left mt-4 mt-sm-0">
          <p class="mt-2 mt-sm-0 font-weight-bold footer-title">Partners</p>
          <div>
            <a
              href="https://dhr.alabama.gov/child-care/quality-overview/child-care-quality-enhancement-agencies-comprehensive/"
              target="_blank"
            >
              Quality Enhancement Agencies, Comprehensive
            </a>
            <a
              href="https://dhr.alabama.gov/child-care/quality-overview/child-care-quality-enhancement-agencies/"
              target="_blank"
              class="mt-2 d-flex"
            >
              Quality Enhancement Agencies, Targeted
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg mt-xl-0 order-4 text-center">
        <div class="d-sm-inline-block text-left text-sm-center text-lg-left mt-4 mt-sm-0">
          <p class="font-weight-bold footer-title">Provided By</p>
          <a href="https://dhr.alabama.gov/child-care/" target="_blank">Alabama Department of Human Resources</a>
          <br />
          <!--Small portion-->
          <span class="footer-text-small">
            <address>
              50 N. Ripley St<br />
              P.O. Box 304000<br />
              Montgomery, AL 36130-4000
            </address>
          </span>
          <!--END small portion-->
        </div>
      </div>
    </div>
    <div id="footer-info-box-shadow-container" class="row">
      <div id="footer-info-box-shadow"></div>
    </div>
    <div id="footer-copyright" class="row white pl-1 pl-sm-4">
      <div class="col">
        &copy; {{ year }} Alabama Department of Human Resources all rights reserved. This project is funded through a
        contract with the Alabama Department of Human Resources.
        <a href="https://oit.alabama.gov/resources/privacy-statements/" target="_blank" rel="noopener noreferrer"
          >Terms & Conditions</a
        >
      </div>
    </div>
    <modal
      v-show="isReportModalVisible"
      @close="closeReportModal"
      :isModalVisible="isReportModalVisible"
      :mustViewAll="false"
      :modalBusy="isBusy"
    >
      <h2 slot="header">Alabama Pathways Report</h2>
      <div class="text-left row" slot="body">
        <div v-if="isReportModalVisible" class="col">
          <docViewer :downloadButtonText="'Download Alabama Pathways Report'" :fileCode="'Alabama-Pathways-Report'"></docViewer>
        </div>
      </div>
    </modal>
    <modal
      v-show="isLegendModalVisible"
      @close="closeLegendModal"
      :isModalVisible="isLegendModalVisible"
      :mustViewAll="false"
      :modalBusy="isBusy"
    >
      <h2 slot="header">Training Legend</h2>
      <div class="text-left row" slot="body">
        <div v-if="isLegendModalVisible" class="col">
          <docViewer :downloadButtonText="'Download Training Legend'" :fileCode="'Training-Legend'"></docViewer>
        </div>
      </div>
    </modal>
    <modal
      v-show="isFaqModalVisible"
      @close="closeFaqModal"
      :isModalVisible="isFaqModalVisible"
      :mustViewAll="false"
      :modalBusy="isBusy"
    >
      <h2 slot="header">Frequently Asked Questions</h2>
      <div class="text-left row" slot="body">
        <div v-if="isFaqModalVisible" class="col">
          <docViewer :downloadButtonText="'Download FAQ'" :fileCode="'faq'"></docViewer>
        </div>
      </div>
    </modal>
    <span id="commithash" style="display: none">{{ githash }}</span>
  </footer>
</template>

<script>
import docViewer from '@components/utility/docViewer';
export default {
  data() {
    return {
      isReportModalVisible: false,
      isLegendModalVisible: false,
      isFaqModalVisible: false,
      acceptableUseText: '',
      year: new Date().getFullYear().toString(),
      // This is injected from webpack.config.js line below tells eslint that
      /*global GIT_HASH*/
      githash: process.env.GIT_HASH,
      isBusy: false
    };
  },
  components: {
    docViewer
  },
  methods: {
    showReportModal() {
      this.isReportModalVisible = true;
    },
    closeReportModal() {
      this.isReportModalVisible = false;
    },
    showLegendModal() {
      this.isLegendModalVisible = true;
    },
    closeLegendModal() {
      this.isLegendModalVisible = false;
    },
    showFaqModal() {
      this.isFaqModalVisible = true;
    },
    closeFaqModal() {
      this.isFaqModalVisible = false;
    },
  }
};
</script>
