<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Manage Connections</h1>
          <p>
            Use search feature to find an EC professional to send a coaching request and Pending Request feature to see
            status of all your pending requests.
          </p>
        </div>

        <div role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle.search>
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon :icon="searchOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
                </button>
                <span class="ml-4">Search Users</span>
              </span>
            </b-card-header>
            <b-collapse id="search" accordion="coaches-accordion" role="tabpanel" v-model="searchOpen">
              <b-card-body>
                <div>
                  <toggleSearches
                    @agencyInput="agencySelected"
                    @nameInput="nameSearched"
                    :agencySearchHelpText="agencySearchHelpText"
                    :userSearchHelpText="userSearchHelpText"
                  ></toggleSearches>
                </div>
                <div v-if="gridKey.startsWith('open')">
                  <grid
                    :entityTypeId="userEntityType"
                    :formId="userFormId"
                    :entityTypeName="entityTypeName"
                    :showFormOnEmpty="false"
                    :showAddButton="false"
                    :key="gridKey"
                    :primaryEntity="gridPrimaryEntity"
                    :altGridId="0"
                    :showDisable="false"
                    :showEdit="false"
                    :showView="false"
                    ref="userGrid"
                    :additionalShowColumns="additionalColumns"
                    :additionalActionsOnColumn="true"
                    :additionalActions="additionalActions"
                    :checkForAdditionalActions="shouldShowAdditionalActions"
                    :allowDownload="false"
                    :defaultFilters="gridDefaultFilters"
                    :basePageSize="gridPageSize"
                    :baseCurrentPage="gridCurrentPage"
                    @setPageSize="setPageSize"
                    @setCurrentPage="setCurrentPage"
                  ></grid>
                </div>
                <div v-if="gridKey == 'closed'" style="min-height: 200px"></div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle.requests>
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon :icon="requestsOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
                </button>
                <span class="ml-4">Pending Requests</span>
              </span>
            </b-card-header>
            <b-collapse id="requests" accordion="coaches-accordion" role="tabpanel" v-model="requestsOpen">
              <b-card-body>
                <div v-if="requestsOpen">
                  <grid :entityTypeId="coachActionEntityType"
                        :formId="0"
                        :entityTypeName="coachActionEntityTypeName"
                        :showFormOnEmpty="false"
                        :showAddButton="false"
                        :key="requestsGridKey"
                        :altGridId="1"
                        :showDisable="false"
                        :showEdit="false"
                        :showView="false"
                        ref="requestsGrid"
                        :multiRowSelect="multiRowSelect"
                        :allowSelectAll="allowSelectAll"
                        :allowClearSelection="allowClearSelection"
                        :additionalShowColumns="additionalColumns"
                        :additionalActionsOnColumn="true"
                        :additionalActions="additionalActions"
                        :checkForAdditionalActions="shouldShowAdditionalActions"
                        :tableActions="tableActions"
                        @tableAction="tableAction"
                        :basePageSize="requestsGridPageSize"
                        :baseCurrentPage="requestsGridCurrentPage"
                        @setPageSize="setRequestsPageSize"
                        @setCurrentPage="setRequestsCurrentPage"
                      >
                  </grid>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">Coaching History</h2>
      <div class="text-left row" slot="body">
        <div v-if="activeUser.id" class="w-100 m-3">
          <actionHistory
            :key="activeId"
            :item="activeUser"
            :requestCoach="requestCoach"
            :withdrawCoachRequest="withdrawCoachRequest"
            :closeCoach="closeCoach"
            :shouldShowRequestLink="shouldShowRequestLink(activeUser)"
            :shouldShowWithdrawLink="shouldShowWithdrawLink(activeUser)"
            :shouldShowCloseLink="shouldShowCloseLink(activeUser)"
            :isBusy="isBusy"
          ></actionHistory>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import toggleSearches from './toggle-searches.vue';
import { mapGetters } from 'vuex';
import actionHistory from './coach-action-history.vue';
import RequestPrompt from '../../utility/dialog/request-prompt';
import RequestPromptRequired from '../../utility/dialog/request-prompt-required';
//import requestDetails from '../coach-requests';
import { getText } from '@scripts/localized';

export default {
  name: 'ManageConnections',
  components: {
    toggleSearches,
    actionHistory,
    //requestDetails
  },
  computed: {
    ...mapGetters(['userId', 'isAuthenticated', 'activeRole'])
  },
  data() {
    return {
      filterOrganizationId: '',
      isBusy: false,
      userEntityType: 1,
      userFormId: 1,
      entityTypeName: 'User',
      coachActionEntityType: 22,
      coachActionEntityTypeName: 'CoachActionRequest',
      multiRowSelect: true,
      allowSelectAll: false,
      allowClearSelection: true,
      organizationEntity: {},
      gridPrimaryEntity: {},
      additionalActions: [
        {
          title: 'Send coaching request',
          icon: 'people-arrows',
          click: this.requestCoach,
          check: this.shouldShowRequestLink
        },
        {
          title: 'Withdraw pending coaching request',
          icon: 'user-times',
          click: this.withdrawCoachRequest,
          check: this.shouldShowWithdrawLink
        },
        {
          title: 'End Coaching',
          icon: 'user-minus',
          click: this.closeCoach,
          check: this.shouldShowCloseLink
        },
        {
          title: 'View Request History',
          icon: 'history',
          click: this.viewRequestHistory,
          check: this.shouldShowRequestHistoryLink
        }
      ],
      tableActions: [
        {
          status: 'Withdrawn',
          verb: 'withdraw',
          button: {
            classes: 'btn tnpalBGRed px-2',
            title: 'Withdraw Selected',
            click: 'tableAction("withdraw")',
            icon: 'user-times'
          },
        },
      ],
      additionalColumns: [],
      agencyAdditionalColumns: ['titlePosition'],
      isModalVisible: false,
      activeId: '',
      activeUser: {},
      activeGrid: '',
      isDirty: false,
      actionStatuses: {
        requested: 1,
        approved: 2,
        rejected: 3,
        closed: 4,
        withdrawn: 5
      },
      searchOpen: true,
      requestsOpen: false,
      searchedName: '',
      gridKey: 'closed',
      requestsGridKey: 'closed',
      searchDefaultFilters: [
        {
          operation: 'Contains',
          filterValue: '',
          filterColumn: { propertyName: 'SearchableName' }
        },
        {
          operation: 'Equals',
          filterValue: 'true',
          filterColumn: { propertyName: 'HasECProfessionalRoleOnly' }
        }
      ],
      gridDefaultFilters: [],
      agencySearchHelpText: `Search by child care <u>${getText(
        'agency'
      ).toLowerCase()} name</u> to see staff associated with that specific program. Click on the program name. Once displayed, click on action icon to send a coaching request.`,
      userSearchHelpText:
        "Search by <u>EC professional's name.</u> Once displayed, click on action icon to send a coaching request.",
      gridPageSize: 5,
      gridCurrentPage: 1,
      requestsGridPageSize: 5,
      requestsGridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => { }
    },
    startOnRequests: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.startOnRequests) {
      this.searchOpen = false;
      this.requestsOpen = true;
    }
  },
  watch: {
    filterOrganizationId: function (newVal) {
      //if (newVal && this.activeGrid)
      if (newVal)
        this.gridPrimaryEntity = { Id: newVal, EntityTypeId: 2 };
      else this.gridPrimaryEntity = {};
    },
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    setRequestsPageSize(size) {
      this.requestsGridPageSize = parseInt(size);
    },
    setRequestsCurrentPage(page) {
      this.requestsGridCurrentPage = parseInt(page);
    },
    agencySelected(organization) {
      if (organization && organization.key && this.searchOpen) {
        this.gridKey = 'open' + organization.key;
        this.additionalColumns = this.agencyAdditionalColumns;
        this.organizationEntity = organization;
        this.filterOrganizationId = organization.key;
      } else {
        this.gridKey = 'closed';
        this.additionalColumns = [];
        this.organizationEntity = {};
        this.filterOrganizationId = '';
      }
    },
    nameSearched(name) {
      if (name && this.searchOpen) { 
        this.gridKey = 'open';
        this.additionalColumns = [];
        this.searchedName = name;
        this.searchDefaultFilters[0].filterValue = name;
        this.gridDefaultFilters = this.searchDefaultFilters;
        if (this.$refs.userGrid && this.$refs.userGrid.loadSummary) this.$refs.userGrid.loadSummary();
      } else {
        this.gridKey = 'closed';
        this.additionalColumns = [];
        this.searchedName = '';
        this.gridDefaultFilters = [];
      }
    },
    shouldShowAdditionalActions(item) {
      return (
        this.shouldShowRequestLink(item) ||
        this.shouldShowWithdrawLink(item) ||
        this.shouldShowCloseLink(item) ||
        this.shouldShowRequestHistoryLink(item)
      );
    },
    shouldShowRequestLink(item) {
      if (this.requestsOpen)
        return false;
      return !item.coachActive && !item.coachRequestPending;
    },
    shouldShowWithdrawLink(item) {
      if (this.requestsOpen)
        return true;
      return !item.coachActive && item.coachRequestPending;
    },
    shouldShowCloseLink(item) {
      if (this.requestsOpen)
        return false;
      return item.coachActive;
    },
    shouldShowRequestHistoryLink(item) {
      if (this.requestsOpen)
        return false;
      return item.coachRelationshipActionCount && item.coachRelationshipActionCount > 0;
    },
    viewRequestHistory(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeUser = item;
        this.showModal();
      }
    },
    async requestCoach(item) {
      if (item && item.id) {
        const title = 'Confirm Send Request';
        const text =
          'Are you sure you want to request to be a Coach of ' +
          item.fullName +
          '? If so, please enter an optional comment and click Continue.';
        const component = RequestPrompt;
        const body = {
          primaryEntityId: item.id,
          coachId: this.userId,
          action: this.actionStatuses.requested 
        };
        await this.performAction(title, text, component, body);
      }
    },
    async withdrawCoachRequest(item) { //Needed even with WREG-1778
      if (item) {
        let body = {};
        if (item.lastCoachRelationshipActionId) {
          body = {
            coachRelationshipActionIds: [item.lastCoachRelationshipActionId],
            action: this.actionStatuses.withdrawn
          };
        }
        else {
          body = {
            coachRelationshipActionIds: [item.id],
            action: this.actionStatuses.withdrawn
          };
        }
        const title = 'Confirm Withdraw Request';
        const text =
          'Are you sure you want to withdraw this request with ' +
          item.fullName +
          '? If so, please enter an optional comment and click Continue.';
        const component = RequestPrompt;
        await this.performAction(title, text, component, body);
      }
    },
    async closeCoach(item) {
      if (item && item.coachRelationshipId) {
        const title = 'Confirm End Coaching';
        const text =
          'Are you sure you want to end the coaching with ' +
          item.fullName +
          '? If so, please enter a comment and click Continue.';
        const component = RequestPromptRequired;
        const body = {
          coachRelationshipId: item.coachRelationshipId,
          action: this.actionStatuses.closed
        };
        await this.performAction(title, text, component, body);
      }
    },
    async tableAction(eventVal, items) {
      if (eventVal === 'withdraw') {
        const title = 'Confirm Withdraw Request';
        const text = 'Are you sure you want to withdraw these requests? If so, please enter an optional comment and click Continue.';
        const component = RequestPrompt;
        this.performBulkAction(items, this.actionStatuses.withdrawn, title, text, component);
      }
    },
    async performAction(promptTitle, promptText, comp, body) {
      return await new Promise((resolve, reject) => {
        const options = {
          title: promptTitle,
          okLabel: 'Continue',
          cancelLabel: 'Cancel',
          prompt: {
            invalidMessage: '',
            component: comp
          }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            //if we clicked continue
            if (res && res.ok && res.ok == true) {
              this.isBusy = true;
              body.comments = res.value;
              this.$store
                .dispatch('coachRelationshipActionRequest', body)
                .then(async (response) => {
                  if (this.$refs.userGrid) {
                    this.$refs.userGrid
                      .loadPage(this.$refs.userGrid.currentPage)
                      .then((response) => {
                        if (this.$refs.userGrid.gridData && this.activeUser.id) {
                          const newUserData = this.$refs.userGrid.gridData.find((user) => user.id == this.activeUser.id);
                          if (newUserData) this.activeUser = newUserData;
                        }
                        this.isBusy = false;
                        this.requestsGridKey += '-1' //need to refresh both grids
                        resolve(true); 
                      })
                      .catch((errors) => {
                        this.isBusy = false;
                        reject(errors);
                      });
                  }
                  if (this.$refs.requestsGrid) {
                    this.isBusy = false;
                    this.requestsGridKey += '-1'
                    this.gridKey += '-1'
                    resolve(true);
                  }
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            }
            //we closed the window
            resolve(false);
          })
          .catch((error) => reject(error));
      });
    },
    async performBulkAction(ids, action, promptTitle, promptText, comp) {
      return await new Promise((resolve, reject) => {
        const options = {
          title: promptTitle,
          okLabel: 'Continue',
          cancelLabel: 'Cancel',
          prompt: {
            invalidMessage: '',
            component: comp
          }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            //if we clicked Continue
            if (res && res.ok && res.ok == true) {
              this.isBusy = true;
              const body = {
                coachRelationshipActionIds: ids,
                action: action,
                comments: res.value
              };
              body.comments = res.value;
              this.$store
                .dispatch('coachRelationshipActionRequest', body)
                .then(async (response) => {
                  this.isBusy = false;
                  this.requestsGridKey += '-1';
                  this.gridKey += '-1';
                  resolve(true);
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            } else { //we clicked Cancel
              resolve(false);
            }
            //we closed the window
            resolve(false);
          })
          .catch((error) => reject(error));
      });
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => reject(error));
        } else resolve(true);
      });
    },
    showModal() {
      this.isModalVisible = true;
    },
    async closeModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.activeId = 'closed';
        this.activeUser = {};
      }
    },
  }
};
</script>
