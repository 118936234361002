<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">My {{ $t('message.agencyPlural') }}</h1>
        </div>
        <!-- Main grid for page (Organization) -->
        <div>
          <div>
            <grid :entityTypeId="orgEntityTypeId"
                  :formId="formId"
                  :entityTypeName="orgEntityTypeName"
                  :primaryEntity="primaryentity"
                  :altGridId="altGridId"
                  :key="gridKey"
                  :showFormOnEmpty="false"
                  :showAddButton="false"
                  :showView="false"
                  :showEdit="false"
                  :showDisable="false"
                  ref="organizationGrid"
                  :additionalActions="additionalActions"
                  :additionalActionsOnColumn="true"
                  :checkForAdditionalActions="shouldShowAdditionalActions"
                  :basePageSize="gridPageSize"
                  :baseCurrentPage="gridCurrentPage"
                  @setPageSize="setPageSize"
                  @setCurrentPage="setCurrentPage"
                >
            </grid>
          </div>
        </div>
        <!-- Dependent grid (User)  -->
        <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
          <h2 slot="header">
            <span v-if="currentOrganizationName">Users in {{ $t('message.agency') }} - {{ currentOrganizationName }}</span>
          </h2>
          <div class="text-left row" slot="body">
            <div v-if="filterOrganizationId" class="w-100 m-3">
              <organizationUsers :key="filterOrganizationId"
                     :organizationId="filterOrganizationId"
                     :organizationName="currentOrganizationName"
                     :formId="userFormId"
                     :entityTypeName="entityTypeName">
               </organizationUsers>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { locale } from '@scripts/localized';
  import organizationUsers from './organization-users.vue';
  export default {
    name: 'Organizations',
    components: {
      organizationUsers
    },
    computed: { 
      ...mapGetters(['userId', 'isAuthenticated']),
    },
    props: {
      primaryentity: { 
        type: Object,
        default: () => { }
      }
    },
    watch: {
      filterOrganizationId: function (newVal) {
        if (newVal) this.organizationEntity = { Id: newVal, Name: this.currentOrganizationName, EntityTypeId: this.orgEntityTypeId };
        else this.organizationEntity = {};
      }
    },
    data() { 
      return {
        isBusy: false,
        locale: locale,
        filterOrganizationId: '',
        currentOrganizationName: '',
        orgEntityTypeId: 2,
        orgEntityTypeName: 'Organization',
        organizationEntity: {},
        formId: 13, //form required for grid.vue but not used here
        altGridId: 1,
        userEntityType: 1,
        userFormId: 1,
        entityTypeName: 'User',
        gridKey: 'open',
        additionalActions: [
          {
            title: 'View Facility',
            icon: 'eye',
            click: this.organizationSelected,
            check: this.shouldShowViewAction
          },
        ],
        additionalColumns: [],
        isModalVisible: false,
        isUserRecordModalVisible: false,
        isDirty: false,
        isLoading: false,
        emptySetMessage: 'No results found',
        gridPageSize: 5,
        gridCurrentPage: 1
      };
    },
    created() {
      if (this.$route && this.$route.meta) {
        if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      }
    },
    methods: {
      setPageSize(size) {
        this.gridPageSize = parseInt(size);
      },
      setCurrentPage(page) {
        this.gridCurrentPage = parseInt(page);
      },
      organizationSelected(organization) {
        if (organization && organization.organizationId) {
          this.currentOrganizationName = organization.organizationName
          this.gridKey = 'open' + organization.organizationId;
          this.additionalColumns = this.agencyAdditionalColumns;
          this.organizationEntity = organization;
          this.filterOrganizationId = organization.organizationId;
          this.showModal();
        } else {
          this.gridKey = 'closed';
          this.additionalColumns = [];
          this.organizationEntity = {};
          this.filterOrganizationId = '';
        }
      },
      shouldShowAdditionalActions(item) {
        return true;
      },
      shouldShowViewAction(organization) {
        if (organization && organization.staffCount > 0)
          return true;
        return false;
      },
      showModal() {
        this.isModalVisible = true;
      },
      async closeModal() {
        const userAccept = await this.dirtyCheck();
        if (userAccept) {
          this.isModalVisible = false;
          this.gridKey = 'closed';
        }
      },
      async dirtyCheck() {
        return await new Promise((resolve, reject) => {
          if (this.isDirty && this.isAuthenticated) {
            const options = {
              title: 'Unsaved Data Warning',
              cancelLabel: 'Cancel'
            };
            this.$dialogs
              .confirm('Are you sure you want to close without saving?', options)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  this.$emit('dirty', false);
                  this.isDirty = false;
                  resolve(true);
                } else {
                  resolve(false);
                }
              })
              .catch((error) => reject(error));
          } else resolve(true);
        });
      },
      formDirty(eventVal) {
        this.isDirty = eventVal;
        this.$emit('dirty', eventVal);
      }
    }
  };
</script>
