<template>
  <section>
  </section>
</template>

<script>
export default {
  name: 'Training',
  data() {
    return {};
  }
};
</script>
