<template>
  <section>
    <div>
      <div id="content_main_holder" class="header_about">
        <!--Main holder--->
        <!--inner-->
        <div id="content_main_inner">
          <div class="about_headline">About Alabama Pathways</div>
        </div>
        <!--END inner-->
      </div>
      <!--END main holder-->
      <!--Body start-->
      <div id="about_body_holder">
        <div id="content_body">
          <div id="content_col_1">
            <h1 class="my-4">Professional development, personalized</h1>
            <h2 class="my-3">What is Alabama Pathways?</h2>
            <div class="body_text">
              <p>
                Alabama Pathways is a free online resource for early childhood professionals working in child care
                facilities. The professional development system addresses the training and education needs of caregivers
                and teachers in the field of early childhood and school-age care. The goal of Alabama Pathways is to
                provide a path to support and advance the best practices of those who care for and teach children from
                birth through 12 years.
              </p>
            </div>
            <h2 class="my-3">Who Should Use Alabama Pathways?</h2>
            <div class="body_text">
              <p>
                Early childhood professionals working in child care programs should create an Alabama Pathways account
                to better support their training and professional development. Caregivers, teachers, directors, and
                other support staff will find benefit from Alabama Pathways being a single, web-based location designed
                to collect and preserve credentials, education, awards, and completed trainings. Programs that are
                licensed and/or participate in Alabama Quality STARS are required to be enrolled in Alabama Pathways.
              </p>
            </div>
            <h2 class="my-3">How does Alabama Pathways help with my training requirements?</h2>
            <div class="body_text">
              <p>
                Alabama Pathways addresses the need for a comprehensive professional development registry that
                incorporates the key elements necessary for improving quality care and education in child care
                facilities. Early childhood professionals can document all required training for both licensing
                requirements and health and safety guidelines in Alabama Pathways.
              </p>
              <p>
                Once you complete a training, your Alabama Pathways record will be updated to reflect the details of the
                training, including the training topic and number of training hours earned. Entering your own name and
                personal email address correctly in Alabama Pathways ensures the accurate transfer of your training
                information from trainers into Alabama Pathways.
              </p>
            </div>
          </div>
          <!--col 2-->
          <div id="content_col_2">
            <!--Banner block-->
            <div class="content_banner_block">
              <div>
                <img src="../../../images/pages/about_illustration.png" alt="" />
              </div>
              <div id="about_button_holder">
                <!--Button -->
                <a class="general_super_button" href="login/register">
                  <span class="about_super_button_text_1">Create Account</span>
                  <span class="about_super_button_text_2">Get started for free today!</span>
                </a>
              </div>
            </div>
            <!--END banner block-->
            <!--Caption-->
            <div class="caption">
              Alabama Pathways provides powerful tools to create your own early childhood professional training profile.
            </div>
            <!--END caption-->
          </div>
          <!--END body-->
        </div>
        <!--END body holder-->
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
};
</script>
