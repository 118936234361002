<template>
  <div :class="isBusy ? 'disabled-area ' : ''">
    <h1 class="hidden-title">User Access Permissions</h1>
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div>
      <div role="tablist">
        <div>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle.general_access>
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon
                    :icon="activeAccordion == 'general_access' ? 'caret-down' : 'caret-right'"
                    class="ml-2"
                    style="color: dimgray"
                  ></icon>
                </button>
                <span class="ml-4">General Access</span>
              </span>
            </b-card-header>
            <b-collapse id="general_access" accordion="access-accordion" visible role="tabpanel">
              <b-card-body>
                <div v-for="role in generalRolesCanAccess()" :key="role.name">
                  <b-form-checkbox
                    switch
                    size="md"
                    :checked="isRoleActive(role.name, null)"
                    @change="updateRole($event, role.name, null, null)"
                    :disabled="shouldGeneralBeDisabled(role.name) ? true : false"
                  >
                    {{ role.display }}
                    <a class="mouse-pointer mx-1 form-tooltip" v-if="shouldGeneralBeDisabled(role.name)">
                      <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
                      <span class="form-tooltiptext" v-if="role.name == '100'"
                        >EC Professional Role cannot be disabled while user has active Elevated or Organization Roles</span
                      >
                      <span class="form-tooltiptext" v-if="role.name == '350' || role.name == '375'"
                        >User may not have both DHS Staff and DHS Supervisor roles</span
                      >
                    </a>
                  </b-form-checkbox>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div v-for="(org, orgIndex) in userOrgRoleInfo" :key="org.id">
          <b-card no-body>
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle="'organization_' + orgIndex"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon :icon="activeAccordion == 'organization_' + orgIndex ? 'caret-down' : 'caret-right'"
                        class="ml-2"
                        style="color: dimgray"></icon>
                </button>
                <span class="ml-4">
                  {{ org.organizationName }}
                </span>
                <b-badge pill variant="secondary" size="md">
                  {{ org.roles.length }}
                  <span class="sr-only">roles in {{ org.organizationName }}</span>
                </b-badge>
                <button @click="fileDownload(org.organizationId)"  v-if="locale === 'tn' && org.roles.length > 0" class="float-right btn btn-outline-primary"><icon class="mr-2" icon="book"></icon>Training Report</button>
              </span>
            </b-card-header>
            <b-collapse :id="'organization_' + orgIndex" accordion="access-accordion" role="tabpanel">
              <b-card-body>
                <div v-for="role in orgRoles" :key="role.name">
                  <b-form-checkbox
                    switch
                    size="md"
                    :checked="isRoleActive(role.name, org.organizationId)"
                    @change="updateRole($event, role.name, org.organizationId, org.employmentRecordId)"
                    :disabled="shouldBeEnabled(role.name, org.organizationId) ? false : true"
                  >
                    {{ role.display }}
                  </b-form-checkbox>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getText, locale } from '@scripts/localized';

export default {
  name: 'UserRoles',
  data() {
    return {
      isBusy: false,
      userOrgRoleInfo: [],
      userGeneralRoleInfo: [],
      orgRoles: [
        {
          name: '100',
          display: 'Staff Level'
        },
        {
          name: '200',
          display: 'Director Level'
        }
      ],
      generalRoles: [
        {
          name: '100',
          display: 'EC Professional Access (100 Level)'
        },
          {
          name: '250',
          display: 'QEA'
        },      
        {
          name: '300',
          display: 'Coach'
        },
        {
          name: '350',
          display: getText('threeFiftyRole')
        },
        {
          name: '375',
          display: 'DHS Supervisor'
        },
        {
          name: '400',
          display: `${getText('textID')} Manager`
        },
        {
          name: '500',
          display: `${getText('textID')} Administrator`
        },
        {
          name: 'ApiUser',
          display: 'API Access'
        },
        {
          name: 'AdminUser',
          display: 'Admin User Access'
        }
      ],
      activeAccordion: 'general_access',
      locale: locale
    };
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['roles', 'activeRole']),
    hasOrgRoles: function () {
      return (
        this.userOrgRoleInfo &&
        this.userOrgRoleInfo.length > 0 &&
        this.userOrgRoleInfo.some((orgRole) => orgRole.organizationId && orgRole.roles && orgRole.roles.length > 0)
      );
    }
  },
  created() {
    if (['al'].includes(locale)) {
      this.generalRoles = this.generalRoles.filter(function(item) {return (item.name != '300' && item.name != '375')}); //remove user 300(coach role)
    }
    if (['tn'].includes(locale)) {
      this.generalRoles = this.generalRoles.filter(function(item) {return item.name != '250'}); //remove user 250 (QEA)
    }
    if (this.user && this.user.id) {
      const body = {
        userId: this.user.id
      };
      this.isBusy = true;
      this.$store
        .dispatch('userOrganizationRolesRequest', body)
        .then(async (response) => {
          this.isBusy = false;
          this.userGeneralRoleInfo = response.filter((access) => !access.organizationId);
          this.userOrgRoleInfo = response.filter((access) => access.organizationId);
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (isJustShown) {
        this.activeAccordion = collapseId;
      } else if (this.activeAccordion == collapseId) {
        this.activeAccordion = '';
      }
    });
  },
  methods: {
    isRoleActive: function (roleName, orgId) {
      let value = false;
      if (orgId) {
        const oRoles = this.userOrgRoleInfo.find((orgRole) => orgRole.organizationId == orgId);
        if (oRoles && oRoles.roles && oRoles.roles.length > 0)
          value = oRoles.roles.some((role) => role.roleName == roleName);
      } else {
        if (
          this.userGeneralRoleInfo &&
          this.userGeneralRoleInfo[0] &&
          this.userGeneralRoleInfo[0].roles &&
          this.userGeneralRoleInfo[0].roles.length > 0
        )
          value = this.userGeneralRoleInfo[0].roles.some((role) => role.roleName == roleName);
      }
      return value;
    },
    hasElevatedRoles: function () {
      let value = false;
      if (
        this.userGeneralRoleInfo &&
        this.userGeneralRoleInfo[0] &&
        this.userGeneralRoleInfo[0].roles &&
        this.userGeneralRoleInfo[0].roles.length > 0
      ) {
        value = this.userGeneralRoleInfo[0].roles.some((role) => role.roleName != '100')
        return value;
      };
    },
    shouldGeneralBeDisabled: function (roleName) {
      if ((roleName == '100' && this.isRoleActive(roleName, null) && this.hasOrgRoles) || this.isBusy) return true;
      if ((roleName == '100' && this.isRoleActive(roleName, null) && this.hasElevatedRoles()) || this.isBusy) {
        return true;
      }
      if (roleName == '350' 
          && this.userGeneralRoleInfo 
          && this.userGeneralRoleInfo[0] 
          && this.userGeneralRoleInfo[0].roles 
          && this.userGeneralRoleInfo[0].roles.some((role) => role.roleName == '375'))
          return true;
      if (roleName == '375' 
          && this.userGeneralRoleInfo 
          && this.userGeneralRoleInfo[0] 
          && this.userGeneralRoleInfo[0].roles 
          && this.userGeneralRoleInfo[0].roles.some((role) => role.roleName == '350'))
          return true;
      return false;
    },
    updateRole: function (selected, roleName, orgId, employmentRecordId) {
      let permissions;
      let body;
      if (orgId) {
        permissions = this.userOrgRoleInfo.find((orgRole) => orgRole.organizationId == orgId);
        body = {
          userId: this.user.id,
          organizationId: orgId,
          roleName: roleName,
          active: selected,
          employmentRecordId: employmentRecordId
        };
      } else if (this.userGeneralRoleInfo[0]) {
        permissions = this.userGeneralRoleInfo[0];
        body = { userId: this.user.id, roleName: roleName };
      }
      if (permissions && permissions.roles) {
        let callName = 'userOrganizationRolesUpdateRequest';
        const hasRole = permissions.roles.some((role) => role.roleName == roleName);
        let mode;
        if (!selected && hasRole) {
          //we want to remove
          if (!orgId) callName = 'removeAccountRoleRequest';
          mode = 'adding';
        } else if (selected && !hasRole) {
          //we want to add
          if (!orgId) callName = 'addAccountRoleRequest';
          mode = 'removing';
        }
        if (mode) {
          this.isBusy = true;
          this.$store
            .dispatch(callName, body)
            .then(async (response) => {
              this.isBusy = false;
              if (mode == 'adding') permissions.roles = permissions.roles.filter((role) => role.roleName != roleName);
              if (mode == 'removing') permissions.roles.push({ roleName: roleName });
            })
            .catch((errors) => {
              this.isBusy = false;
            });
        }
      }
    },
    generalRolesCanAccess() {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
        const active = this.activeRole.role.roleName;
        if (active == 'AdminUser') return this.generalRoles;
        if (active == '400')
          return this.generalRoles.filter(
            (role) => role.name != 'AdminUser' && role.name != '500' && role.name != '400' && role.name != 'ApiUser'
          );
        if (active == '500') return this.generalRoles.filter((role) => role.name != 'AdminUser' && role.name != '500');
      }
      return [];
    },
    shouldBeEnabled(checkboxRole, orgId) {
      if (this.isBusy) return false;
      if (checkboxRole == '200') {
        //shouldn't be able to select the 200 role if we don't have 100 yet
        const oRoles = this.userOrgRoleInfo.find((orgRole) => orgRole.organizationId == orgId);
        if (oRoles && oRoles.roles && oRoles.roles.length > 0)
          return oRoles.roles.some((role) => role.roleName == '100');
        else return false;
      } else if (checkboxRole == '100') {
        //shouldn't be able to deselect the 100 role unless they don't have 200
        const oRoles = this.userOrgRoleInfo.find((orgRole) => orgRole.organizationId == orgId);
        if (oRoles && oRoles.roles && oRoles.roles.length > 0)
          return !oRoles.roles.some((role) => role.roleName == '200');
        else return true;   
      }
      return true;
    },
    async fileDownload(orgId) {
      this.isBusy = true;
      const body = { username: this.user.userName, organizationId: orgId };
      this.$store
        .dispatch('complianceReportRequest', body)
        .then((response) => {
            this.isBusy = false;
          if (response && response.file) {
            const oName = this.strip(this.userOrgRoleInfo.find((orgRole) => orgRole.organizationId == orgId).organizationName);
            const fileName = `TNPAL_TrainingReport_${oName}-${this.user.firstName+this.user.lastName}.pdf`;
            this.savePdf(fileName, response.file);
            const options = { title: 'Download successful.' };
            this.$dialogs.alert(`Check your Downloads folder for a PDF of the report.`, options);
            }
          })
          .catch((errors) => {
            this.isBusy = false;
            this.errors = errors;
          });  
    },
    strip(arr) {
      const arr2 = arr.split(" ");
      for (let item of arr2) {
        const index = arr2.indexOf(item);
        const ewc = item.endsWith(',');
        const ewp = item.endsWith('.');
        const e = item.length - 1;
        if (ewc || ewp) {
          item = item.slice(0, e);
          arr2[index] = item;
        }
        if (item.toLowerCase().includes('llc') || item.toLowerCase().includes('inc')) {
          arr2.splice(index, 1);
        }
      }
      return (arr2.join(" "));
    },
    savePdf(strFileName, strData) {
      const pdfData = 'data:application/pdf;base64,' + escape(strData);

      const link = document.createElement('a');
      link.href = pdfData;
      link.download = strFileName;
      link.click();
    },
  }
};
</script>
