<template>
  <div>
    <div>
      <grid
        id="certGrid"
        :key="gridKey"
        :entityTypeId="entityTypeId"
        :formId="formId"
        :entityTypeName="entityTypeName"
        :primaryEntity="primaryentity"
        :showFormOnEmpty="true"
        :showContinueButton="true"
        :additionalActions="additionalActions"
        :additionalActionsOnColumn="true"
        @continueButton="$emit('continueButton')"
        @dirty="$emit('dirty', $event)"
        :basePageSize="gridPageSize"
        :baseCurrentPage="gridCurrentPage"
        @setPageSize="setPageSize"
        @setCurrentPage="setCurrentPage"
      >
      </grid>
    </div>
  </div>
</template>

<script>
import { locale, getText } from '@scripts/localized';
import { mapGetters } from 'vuex';
export default {
  name: 'ProfileCertification',
  data() {
    return {
      locale: locale,
      isBusy: false,
      entityTypeId: 7,
      formId: 5,
      gridKey: "cert",
      entityTypeName: 'Certification/Credential',
      additionalActions: [
        {
          title: 'Request Verification',
          icon: 'plus-circle',
          click: this.makeVerificationRequest,
          check: this.shouldShowVerificationRequest
        },
        {
          title: 'Verification Not Available',
          icon: 'plus-circle',
          click: this.showDisabledVerificationMessage,
          check: this.shouldShowDisabledVerificationRequest
        }
      ],
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['userOrganizationRoles'])
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    async makeVerificationRequest(item) {
      return await new Promise((resolve, reject) => {
        const confirmText = `Once verification is requested, the record can not be edited unless it is returned by the ${getText('program')}'s director(s) or designee.  Please click Confirm to continue.`
        const confirmOptions = {
          title: "Confirm Verification Request",
          cancelLabel: 'Cancel',
          okLabel: 'Confirm'
        };
        this.$dialogs
          .confirm(confirmText, confirmOptions)
          .then((res) => {
            if (res && res.ok && res.ok == true) {
              return new Promise((resolve, reject) => {
                const body = {
                  entityTypeId: this.entityTypeId,
                  entityId: item.id
                };
                this.isBusy = true;
                this.$store
                  .dispatch('verificationRequestRequest', body)
                  .then(async (response) => {
                    this.isBusy = false;
                    const alertOptions = { title: 'Verification request submitted!' };
                    this.$dialogs.alert(`Your request for verification has been successfully submitted to your ${getText('program')}'s director(s) or designee for review.`, alertOptions);
                    this.gridKey += '-1';
                    resolve(true);
                  })
                  .catch((errors) => {
                    this.isBusy = false;
                    resolve(false);
                  });
              });
            }
          })
          .catch((error) => {
            resolve(false);
          });
        });
    },
    shouldShowVerificationRequest(item) {
      for (let i=0; i < this.userOrganizationRoles.length; i++) {
        if (this.userOrganizationRoles[i].role && this.userOrganizationRoles[i].role.roleName && this.userOrganizationRoles[i].role.roleName === '200')
          return false;
      }
      if (item.verified || (item.verificationStatus == 'Requested'))
        return false;
      else
        return true;
    },
    shouldShowDisabledVerificationRequest(item) {
      for (let i=0; i < this.userOrganizationRoles.length; i++) {
        if (this.userOrganizationRoles[i].role && this.userOrganizationRoles[i].role.roleName && this.userOrganizationRoles[i].role.roleName === '200')
          return true;
      }
      return false;
    },
    showDisabledVerificationMessage() {
      const options = { title: 'Verification Not Available' };
       this.$dialogs.alert(`This action is currently not available for ${getText('agency')} Director(s). Please reach out to ${getText('threeFiftyRole')} for verification of your records.`, options);
    }
  }
};
</script>
<style>
  #certGrid .grid-tr td::before {
    margin-right: 6px;
    width: 40%;
  }
</style>
