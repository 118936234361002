<template>
  <div :class="'main-nav' + (!collapsed ? ' overlay' : '')">
    <div v-if="$parent.checkEnvIndicatorVisible()" :class="$parent.isDev ? 'dev-indicator' : $parent.isUat ? 'uat-indicator' : $parent.isProd ? 'prod-indicator' : ''">
      <strong><span v-html="$parent.envIndicatorText"></span></strong>
    </div>
    <focus-trap :active="!collapsed ? true : false">
      <nav :class="'navbar navbar-expand-lg navbar-dark p-1 m-0 m-lg-0 p-lg-0' + (!collapsed ? ' overlay-content' : '') + ($parent.checkEnvIndicatorVisible() ? ' env-indicator-nav-width-adjust' : '')">
        <!--------Mobile Hamburger Button-------->
        <button :class="'navbar-toggler' + (collapsed ? ' collapsed' : '')" type="button" @click="toggleCollapsed">
          <span class="icon-bar top-bar"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </button>
        <router-link class="navbar-brand mx-auto mr-0 mr-lg-3" to="/" v-if="locale === 'tn'">
          <img id="header-logo" src="../images/logo/FINAL_TN_PAL_LOGO_WEB.png" alt="TNPAL Logo" />
        </router-link>
        <router-link class="navbar-brand mx-auto mr-0 mr-lg-3 ml-lg-5" to="/" v-else-if="locale === 'al'">
          <img id="header-logo" src="../images/logo/alp_logo.jpg" alt="Alabama Logo" />
        </router-link>
        <router-link class="navbar-brand mx-auto mr-0 mr-lg-3" to="/" v-else>
          <img id="header-logo" src="../images/logo/logo_reg360_a.jpg" alt="Registry 360 Logo" />
        </router-link>
        <!--------Message and User Info-------->
        <div class="mt-2 order-lg-2">
          <div :class="'nav-message-badge' + (isFirstTime ? ' d-none' : '')" v-show="isAuthenticated">
            <button
              v-show="hasNotification"
                    id="notificationBadge"
                    @click="notifications"
                    :class="(notificationCount > 0 ? 'has-badge ' : '') + 'fa-stack nav-message-badge float-left'"
                    :data-count="notificationCount"
                    :title="
                    'View Notifications' +
                (notificationCount > 0 ? ' - ' + notificationCount + ' unread notifications' : '')
              "
              style="border: none; font-size: 1.8rem; width: 1.8em; height: 1.8em"
              >
              <icon :icon="['far', 'bell']" class="fa-stack-1x" style="width: 1.6rem; height: 1.6rem"></icon>
            </button>
            <button
              v-show="hasMessaging"
                    id="messageBadge"
                    @click="messages"
                    :class="(messageCount > 0 ? 'has-badge ' : '') + 'fa-stack nav-message-badge float-left'"
                    :data-count="messageCount"
                    :title="'View Messages' + (messageCount > 0 ? ' - ' + messageCount + ' unread messages' : '')"
                    style="
                border-width: 0px 1px 0px 0px;
                border-style: solid;
                border-color: #999;
                font-size: 1.8rem;
                width: 1.8em;
                height: 1.8em;
              "
            >
              <icon :icon="['far', 'envelope']" class="fa-stack-1x" style="width: 1.6rem; height: 1.6rem"></icon>
            </button>
            <div id="user-info" class="float-left nav-message-badge" v-show="isAuthenticated">
              <b-dropdown variant="link" toggle-class="text-decoration-none" right>
                <template v-slot:button-content>
                  <span class="fa-stack">
                    <icon
                      :icon="'user'"
                          title="View User Information"
                          class="fa-stack-1x nav-message-badge"
                      style="width: 1.6rem; height: 1.6rem; margin-top: 6px"
                    ></icon>
                  </span>
                </template>
                <b-dropdown-item class="text-transform-none default-cursor px-0" disabled
                  ><strong>Name:</strong> {{ profile.firstName }} {{ profile.lastName }}</b-dropdown-item
                >
                <b-dropdown-item class="text-transform-none default-cursor px-0" disabled
                  ><strong>Username:</strong> {{ profile.userName }}</b-dropdown-item
                >
                <b-dropdown-item class="text-transform-none default-cursor px-0" disabled
                  ><strong>Registry ID:</strong> {{ profile.registryId }}</b-dropdown-item
                >
                <div v-if="hasMultipleRoles">
                  <div style="height: 5px" class="role-divider text-transform-none"></div>
                  <b-dropdown-item
                    class="text-capitalize px-0"
                                   v-if="hasActiveRole"
                                   active
                    :title="'Currently using ' + activeRole.role.roleName"
                  >
                    {{ roleDisplay[activeRole.role.roleName] }}
                    <b-badge v-if="activeRole.organizations" variant="light" class="ml-2">
                      {{ activeRole.organizations.length }}
                    </b-badge>
                  </b-dropdown-item>
                  <b-dropdown-item-button
                    class="text-capitalize"
                                          v-for="orgRole in nonSelectedRoles"
                                          :key="orgRole.role.roleId"
                                          @click="selectActiveRole(orgRole)"
                    :title="'Select to use ' + roleDisplay[orgRole.role.roleName]"
                  >
                    {{ roleDisplay[orgRole.role.roleName] }}
                    <b-badge v-if="orgRole.organizations" variant="secondary" class="ml-2">
                      {{ orgRole.organizations.length }}
                    </b-badge>
                  </b-dropdown-item-button>
                </div>
              </b-dropdown>
            </div>
          </div>
        </div>
        <!--------Menu-------->
        <div :class="'order-lg-1 collapse navbar-collapse' + (!collapsed ? ' show' : '')" v-show="!collapsed">
          <div v-if="!isAuthenticated || claims.length > 0">
            <ul class="navbar-nav main-nav">
              <li class="nav-item">
                <router-link :to="'/'" exact-active-class="active" exact>
                  <span>Home</span>
                </router-link>
              </li>
              <li
                :class="'nav-item ' + (route.display == 'Dashboard' && isFirstTime ? 'd-none' : '')"
                  v-for="(route, index) in validRoutesForAuthState"
                :key="index"
              >
                <router-link :to="route.path" active-class="active">
                  <span>{{ route.display }}</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="shouldShowFeedback()">
                <a href="javascript:void 0" v-on:click="showFeedbackModal">
                  <span>Feedback</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="javascript:void 0" v-show="isAuthenticated" v-on:click="logoff">
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <span class="nav-item d-inline d-lg-none my-auto" id="mobileLoginLink">
          <router-link :to="'/login'" v-show="!isAuthenticated && collapsed" class="active" active-class="active">
            Login
          </router-link>
        </span>
      </nav>
    </focus-trap>
    <modal v-if="isFeedbackModalVisible" @close="closeFeedbackModal" :isModalVisible="isFeedbackModalVisible">
      <h2 slot="header">Feedback</h2>
      <div class="text-left row" slot="body">
        <div class="w-100 m-3">
          <wfrform
            :formid="feedbackFormId"
            :entities="defaultFeedbackEntity"
            :primaryEntity="getPrimaryEntity()"
            @dirty="formDirty($event)"
            :key="activeFeedback"
            @saved="saveFeedbackModal"
            saveButtonName="Submit"
          >
          </wfrform>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { routes } from '../router/routes';
import { mapGetters } from 'vuex';
import { FocusTrap } from 'focus-trap-vue';
import { locale, getText } from '@scripts/localized';

export default {
  watch: {
    $route() {
      this.collapsed = true;
      document.body.classList.remove('no-scroll');
      window.scrollTo(0, 0);
    },
    isAuthenticated: function (newVal) {
      if (!newVal) {
        this.messageCount = 0;
        this.notificationCount = 0;
      } else {
        this.getMessageCount();
        this.getNotificationCount();
      }
    },
    activeRole: function (newVal) {
      this.getNonSelectedRoles(newVal);
    },
    userOrganizationRoles: function (newVal) {
      this.getNonSelectedRoles(this.activeRole);
    }
  },
  data() {
    return {
      routes,
      locale: locale,
      collapsed: true,
      messageCount: 0,
      notificationCount: 0,
      isBusy: false,
      nonSelectedRoles: [],
      roleDisplay: {
        100: 'EC Professional',
        200: 'Director',
        250: 'QEA',
        300: 'Coach',
        350: getText('threeFiftyRole'),
        375: 'DHS Supervisor',
        400: `${getText('textID')} Manager`,
        500: `${getText('textID')} Administrator`,
        AdminUser: 'Administrator'
      },
      feedbackFormId: 29,
      isFeedbackModalVisible: false,
      activeFeedback: 'key',
      defaultFeedbackEntity: [{ Id: '', EntityTypeId: 32 }],
      isDirty: false
    };
  },
  created() {
    // Listen to score changes coming from SignalR events
    this.$messageHub.$on('count-changed', this.onCountChanged);
    this.$messageHub.$on('notification-count-changed', this.onNotificationCountChanged);
    if (this.isAuthenticated) {
      this.getMessageCount();
      this.getNotificationCount();
    }
  },
  beforeDestroy() {
    // Make sure to cleanup SignalR event handlers when removing the component
    this.$messageHub.$off('count-changed', this.onCountChanged);
    this.$messageHub.$off('notification-count-changed', this.onNotificationCountChanged);
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'claims', 'profile', 'isFirstTime', 'userOrganizationRoles', 'activeRole', 'userId']),
    hasMessaging() {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
        switch (this.activeRole.role.roleName) {
          case '350':
            return (locale === 'al');
          case '300':
          case '375':
            return false;
          default:
            return true;
        }
      }
      return false;
    },
    hasNotification() {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
        switch (this.activeRole.role.roleName) {
          default:
            return true;
        }
      }
      return false;
    },
    hasActiveRole() {
      if (!this.activeRole) return false;
      return Object.keys(this.activeRole).length > 0;
    },
    hasMultipleRoles() {
      return this.userOrganizationRoles && this.userOrganizationRoles.length > 1;
    },
    validRoutesForAuthState: function () {
      return this.routes.filter((x) => this.routeMatchesAuthState(x));
    }
  },
  components: {
    FocusTrap: FocusTrap
  },
    methods: {
    toggleCollapsed: function (event) {
      this.collapsed = !this.collapsed;
      const main = document.body;
      if (main && main.classList) {
        if (this.collapsed) {
          main.classList.remove('no-scroll');
        } else {
          main.classList.add('no-scroll');
        }
      }
    },
    logoff: function () {
      this.$store
        .dispatch('authLogout')
        .then((result) => {
          if (this.$route.name != 'home') this.$router.push({ name: 'home' });
        })
        .catch((errors) => (this.errors = errors));
    },
    routeMatchesAuthState: function (route) {
      if (route.meta && route.meta.notMenuItem) return false;
      if (this.isAuthenticated) {
        if (route.meta && route.meta.hideOnAuth) return false;
      } else {
        if (route.meta && route.meta.requiresAuth) return false;
      }
      if (route.meta && route.meta.requiresClaims) return this.routeMatchesClaims(route);
      return true;
    },
    routeMatchesClaims: function (route) {
      let matches = false;
      this.claims.forEach(function (claim, index) {
        if (matches == true) return;
        if (route.meta.requiresClaims.includes(claim)) {
          matches = true;
          return;
        }
      });
      return matches;
    },
    onCountChanged: function (data) {
      if (data && (data.count || data.count === 0)) this.messageCount = data.count;
    },
    onNotificationCountChanged: function (data) {
      if (data && (data.count || data.count === 0)) this.notificationCount = data.count;
    },
    getMessageCount() {
      this.isBusy = true;
      this.$store
        .dispatch('getMessageCountRequest')
        .then((response) => {
          this.isBusy = false;
          if (response) {
            this.messageCount = response;
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    getNotificationCount() {
      this.isBusy = true;
      this.$store
        .dispatch('getNotificationCountRequest')
        .then((response) => {
          this.isBusy = false;
          if (response) {
            this.notificationCount = response;
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    messages() {
      if (this.$route && this.$route.name && this.$route.name == 'messaging') return;
      this.$router.push({ name: 'messaging' });
    },
    notifications() {
      if (this.$route && this.$route.name && this.$route.name == 'notifications') return;
      this.$router.push({ name: 'notifications' });
    },
    selectActiveRole(role) {
      this.$store.dispatch('roleSwitchRequest', role).then((response) => {
        if (this.$route.name != 'dashboard-main') this.$router.push({ name: 'dashboard-main' });
      });
    },
    getNonSelectedRoles(val) {
      if (!val || !val.role || !val.role.roleId) {
        this.nonSelectedRoles = this.userOrganizationRoles;
        return;
      }
      const orgRoles = [...this.userOrganizationRoles];
      let index;
      for (let i = 0; i < orgRoles.length; i += 1) {
        if (orgRoles[i].role.roleId == val.role.roleId) {
          index = i;
        }
      }
      if (index || index === 0) orgRoles.splice(index, 1);
      this.nonSelectedRoles = orgRoles;
    },
    shouldShowFeedback() {
      return locale == 'tn' && this.isAuthenticated
    },
    showFeedbackModal() {
      this.isFeedbackModalVisible = true;
    },
    async closeFeedbackModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isFeedbackModalVisible = false;
        this.activeFeedback += '-1';
      }
    },
    async saveFeedbackModal() {
      const options = { title: 'Feedback sent.' };
      this.$dialogs.alert(`Thank you for your feedback.`, options);
      this.isFeedbackModalVisible = false;
      this.activeFeedback += '-1';
    },
    getPrimaryEntity() {
      return { Id: this.userId, EntityTypeId: 1 };
    },
   formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => reject(error));
        } else resolve(true);
      });
    }
  }
};
</script>
